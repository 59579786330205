import React from "react";
import {Card, Divider, Table, Icon} from "antd";
import { connect } from "react-redux";
import TopButtonPanel from "../../components/topButtonPanel/EventListPanel";
import axios from "../../util/Api";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import { withRouter } from "react-router";

const expandedRowRender = record => <p>{record.description}</p>;
const title = () => 'Here is title';
const showHeader = true;
const footer = () => 'Here is footer';
const scroll = {y: 240};
const pagination = {position: 'bottom'};

class Dynamic extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      bordered: true,
      loading: false,
      pagination,
      size: "default",
      expandedRowRender: false,
      title: undefined,
      showHeader,
      footer: false,
      rowSelection: false,
      scroll: undefined,
      eventData: [],
      userData: [],
      warning: false,
      edit: false,
      record: [],
      activityData: []
    };
  }

  componentDidMount() {
    this.getData();
    console.log();
  }

  handleToggle = prop => {
    return enable => {
      this.setState({ [prop]: enable });
    };
  };

  handleSizeChange = e => {
    this.setState({ size: e.target.value });
  };

  handleExpandChange = enable => {
    this.setState({
      expandedRowRender: enable ? expandedRowRender : undefined
    });
  };

  handleTitleChange = enable => {
    this.setState({ title: enable ? title : undefined });
  };

  handleHeaderChange = enable => {
    this.setState({ showHeader: enable ? showHeader : false });
  };

  handleFooterChange = enable => {
    this.setState({ footer: enable ? footer : undefined });
  };

  handleRowSelectionChange = enable => {
    this.setState({ rowSelection: enable ? {} : undefined });
  };

  handleScollChange = enable => {
    this.setState({ scroll: enable ? scroll : undefined });
  };

  handlePaginationChange = e => {
    const { value } = e.target;
    this.setState({
      pagination: value === "none" ? false : { position: value }
    });
  };

  handleDelete = record => {
    this.setState({
      warning: true,
      EventId: record.id
    });
  };

  handleEdit = record => {
    this.setState({
      edit: true,
      record,
      EventId: record.id
    });
  };

  onCancelDelete = () => {
    this.setState({
      warning: false
    });
  };

  toggleEdit = edit => {
    this.setState({
      edit
    });
  };

  getData() {
    this.getEvents();
    this.getActivities();
  }

  async getEvents() {
    const { authUser } = this.props;
    if (!authUser) {
      return null;
    }
    const res = await axios.get("event");
    let events = res.data;
    events = events.filter(
      (event, index, self) =>
        index === self.findIndex(curEvent => curEvent.id === event.id)
    );
    if (events) {
      events = events.filter(event => {
        return event.deletedAt === null;
      });
      this.setState({ eventData: events });
    }
  }

  async getActivities() {
    return axios
      .get(`activity`)
      .then(activity => {
        this.setState({ activityData: activity.data });
      })
      .catch(function(error) {
        console.log("Error****:", error.message);
      });
  }

  deleteEvent = () => {
    const { EventId } = this.state;
    return axios
      .delete(`event/${EventId}`)
      .then(res => {
        if (res) {
          this.setState({ EventId: "", warning: false });
          this.getData();
        } else {
        }
      })
      .catch(function(error) {
        console.log("Error****:", error.message);
      });
  };

  renderTopPanel() {
    const { authUser } = this.props;
    const { edit, record } = this.state;
    if (authUser && authUser.role === "superadmin") {
      return (
        <TopButtonPanel
          edit={edit}
          toggleEdit={this.toggleEdit}
          record={record}
          getData={() => this.getData()}
        />
      );
    }
  }

  getDataByDate(isUpcoming) {
    const { eventData } = this.state;
    const todaysDate = new Date();
    return eventData.filter(function(event) {
      const eventDate = new Date(event.startDateTime);
      if (isUpcoming) {
        return eventDate < todaysDate;
      } else {
        return eventDate > todaysDate;
      }
    });
  }

  checkRequiredData(requiredKeys, data) {
    const requiredData = Object.keys(data)
      .filter(key => requiredKeys.includes(key))
      .reduce((obj, key) => {
        obj[key] = data[key];
        return obj;
      }, {});
    if (Object.values(requiredData).includes(false)) {
      return false;
    } else if (Object.values(requiredData).includes("")) {
      return false;
    } else if (Object.values(requiredData).includes(null)) {
      return false;
    } else {
      return true;
    }
  }

  isEventComplete(record) {
    const { eventData, activityData } = this.state;
    const event = eventData.find(curEvent => {
      return curEvent.id === record.id;
    });
    const pendingActivites = activityData.filter(activity => {
      return activity.EventId === record.id && activity.approved === null;
    });
    const requiredStudentGuidesKeys = [
      "numberOfStudentGuides",
      "studentGuidesDietaryRequirements"
    ];
    const requiredAgendaKeys = [
      "name",
      "twitterHandles",
      "twitterHashtags",
      "exhibitionRoomNumber",
      "exhibitionRoomDetails",
      "workshopRoomDetails",
      "earliestAccessAt",
      "workshopSetupAt",
      "registrationStartAt",
      "wifiDetails",
      "directions",
      "cateringContact"
    ];
    const requiredGoveranceDocsKeys = [
      "governanceDocQ1",
      "governanceDocQ2",
      "governanceDocQ3",
      "governanceDocQ4",
      "governanceDocQ5",
      "governanceDocQ6",
      "governanceDocQ7",
      "governanceDocQ8",
      "governanceDocQ9"
    ];
    const requiredRiskAssessmentKeys = [
      "riskAssessmentQ1",
      "riskAssessmentQ2",
      "riskAssessmentQ3"
    ];
    const requiredBriefingKeys = [
      "exhibitionRoomNumber",
      "exhibitionRoomDetails",
      "workshopRoomDetails",
      "earliestAccessAt",
      "workshopSetupAt",
      "registrationStartAt",
      "wifiDetails",
      "directions",
      "cateringContact"
    ];
    const isActivitiesComplete = !pendingActivites.length > 0;
    const isGovernanceDocsComplete = this.checkRequiredData(
      requiredGoveranceDocsKeys,
      event
    );
    const isRiskAssessmentComplete = this.checkRequiredData(
      requiredRiskAssessmentKeys,
      event
    );
    const isBriefingComplete = this.checkRequiredData(
      requiredBriefingKeys,
      event
    );
    const isStudentGuidesComplete = this.checkRequiredData(
      requiredStudentGuidesKeys,
      event
    );
    const isAgendaValid = this.checkRequiredData(requiredAgendaKeys, event);
    const isAgendaComplete = event.agenda
      ? isAgendaValid && event.agenda.length > 0
      : false;
    const isEventComplete =
      isActivitiesComplete &&
      isGovernanceDocsComplete &&
      isRiskAssessmentComplete &&
      isBriefingComplete &&
      isStudentGuidesComplete &&
      isAgendaComplete;

    return isEventComplete;
  }

  render() {
    const { eventData, warning } = this.state;
    const { authUser } = this.props;
    const todaysDate = new Date();
    const upcomingEvents = eventData.filter(function(event) {
      const eventDate = new Date(event.endDateTime);
      return eventDate > todaysDate;
    });
    const pastEvents = eventData.filter(function(event) {
      const eventDate = new Date(event.endDateTime);
      return eventDate < todaysDate;
    });
    const adminColumns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: 350,
        render: (text, record) => (
          <>
            {new Date(record.endDateTime) > new Date() && (
              <Icon
                type={
                  this.isEventComplete(record)
                    ? "check-circle"
                    : "exclamation-circle"
                }
                theme="filled"
                style={{
                  marginRight: 10,
                  color: this.isEventComplete(record) ? "green" : "red"
                }}
              />
            )}

            <span
              onClick={() =>
                this.props.history.push(
                  authUser.role === "superadmin" || authUser.role === "FHE"
                    ? `/event/${record.id}`
                    : `/${record.id}/activities`
                )
              }
              className="gx-link"
              style={{
                marginLeft: 10
              }}
            >
              {text}
            </span>
          </>
        )
      },
      {
        title: "Host Location",
        dataIndex: "hostLocation",
        key: "hostLocation"
      },
      {
        title: "Event Date",
        dataIndex: "startDateTime",
        key: "startDateTime",
        render: (text, record) => <span>{moment(text).format("LL")}</span>
      },
      {
        title: "Action",
        key: "action",
        width: 150,
        render: (text, record) => (
          <span>
            <span onClick={() => this.handleEdit(record)} className="gx-link">
              Edit
            </span>
            <Divider type="vertical" />
            <span
              onClick={() => this.handleDelete(record)}
              className="gx-link"
              style={{ color: "red" }}
            >
              Delete
            </span>
          </span>
        )
      }
    ];

    const userColumns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: 350,
        render: (text, record) => (
          <span
            onClick={() =>
              this.props.history.push(
                authUser.role === "superadmin" || authUser.role === "FHE"
                  ? `/event/${record.id}`
                  : `/${record.id}/activities`
              )
            }
            className="gx-link"
          >
            {text}
          </span>
        )
      },
      {
        title: "Host Location",
        dataIndex: "hostLocation",
        key: "hostLocation"
      },
      {
        title: "Date",
        dataIndex: "startDateTime",
        key: "startDateTime",
        render: (text, record) => <span>{moment(text).format("LL")}</span>
      }
    ];

    if (!authUser) {
      return null;
    }

    return (
      <div>
        {this.renderTopPanel()}
        <SweetAlert
          show={warning}
          warning
          showCancel
          confirmBtnText={"Yes, delete it"}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title={"Are you sure?"}
          onConfirm={() => this.deleteEvent()}
          onCancel={() => this.onCancelDelete()}
        ></SweetAlert>
        {upcomingEvents.length > 0 && (
          <Card>
            <h3>
              <Icon
                type="calendar"
                theme="twoTone"
                style={{ paddingRight: 10 }}
              />{" "}
              My Upcoming Events
            </h3>
            <br />
            <Table
              className="gx-table-responsive"
              {...this.state}
              columns={
                authUser && authUser.role === "superadmin"
                  ? adminColumns
                  : userColumns
              }
              dataSource={upcomingEvents}
              pagination={false}
            />
          </Card>
        )}
        {pastEvents.length > 0 && (
          <Card>
            <h3>
              <Icon type="clock-circle" style={{ paddingRight: 10 }} /> My Past
              Events
            </h3>
            <br />
            <Table
              className="gx-table-responsive"
              {...this.state}
              columns={
                authUser && authUser.role === "superadmin"
                  ? adminColumns
                  : userColumns
              }
              dataSource={pastEvents}
              pagination={false}
            />
          </Card>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser };
};

export default withRouter(connect(mapStateToProps, null)(Dynamic));
