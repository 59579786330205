import React from "react";
import { Card, Form, Table } from "antd";
import UserTopButtonPanel from "../../components/topButtonPanel/UserListPanel";
import axios from "../../util/Api";
import SweetAlert from "react-bootstrap-sweetalert";
const expandedRowRender = record => <p>{record.description}</p>;
const title = () => "Here is title";
const showHeader = true;
const footer = () => "Here is footer";
const scroll = { y: 240 };
const pagination = { position: "bottom" };

class UserList extends React.Component {
  state = {
    bordered: true,
    loading: false,
    pagination,
    size: "default",
    expandedRowRender: false,
    title: undefined,
    showHeader,
    footer: false,
    rowSelection: false,
    scroll: undefined,
    users: [],
    warning: false,
    edit: false,
    record: []
  };

  componentDidMount() {
    this.getUsers();
  }

  handleToggle = prop => {
    return enable => {
      this.setState({ [prop]: enable });
    };
  };

  handleSizeChange = e => {
    this.setState({ size: e.target.value });
  };

  handleExpandChange = enable => {
    this.setState({
      expandedRowRender: enable ? expandedRowRender : undefined
    });
  };

  handleTitleChange = enable => {
    this.setState({ title: enable ? title : undefined });
  };

  handleHeaderChange = enable => {
    this.setState({ showHeader: enable ? showHeader : false });
  };

  handleFooterChange = enable => {
    this.setState({ footer: enable ? footer : undefined });
  };

  handleRowSelectionChange = enable => {
    this.setState({ rowSelection: enable ? {} : undefined });
  };

  handleScollChange = enable => {
    this.setState({ scroll: enable ? scroll : undefined });
  };

  handlePaginationChange = e => {
    const { value } = e.target;
    this.setState({
      pagination: value === "none" ? false : { position: value }
    });
  };

  handleDelete = record => {
    this.setState({
      warning: true,
      EventId: record.id
    });
  };

  handleEdit = record => {
    this.setState({
      edit: true,
      record,
      EventId: record.id
    });
  };

  onCancelDelete = () => {
    this.setState({
      warning: false
    });
  };

  toggleEdit = edit => {
    this.setState({
      edit
    });
  };

  getUsers = () => {
    return axios
      .get("user/search", {
        params: {
          role: "superadmin"
        }
      })
      .then(users => {
        if (users.data) {
          for (var i in users.data) {
            users.data[i].firstName =
              users.data[i].firstName + " " + users.data[i].lastName;
          }
          this.setState({ users: users.data });
        } else {
        }
      })
      .catch(function(error) {
        console.log("Error****:", error.message);
      });
  };

  render() {
    const { users, warning, edit, record } = this.state;
    const columns = [
      {
        title: "Name",
        dataIndex: "firstName",
        key: "firstName",
        width: 200
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        width: 200
      },
      {
        title: "Action",
        key: "action",
        width: 100,
        render: (text, record) => (
          <span onClick={() => this.handleEdit(record)} className="gx-link">
            Edit
          </span>
        )
      }
    ];

    return (
      <div>
        <UserTopButtonPanel
          edit={edit}
          toggleEdit={this.toggleEdit}
          record={record}
          getData={() => this.getUsers() }
        />
        <SweetAlert
          show={warning}
          warning
          showCancel
          confirmBtnText={"Yes, delete it"}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title={"Are you sure?"}
          onConfirm={() => this.deleteEvent()}
          onCancel={() => this.onCancelDelete()}
        ></SweetAlert>
        <Card>
          <Table
            className="gx-table-responsive"
            {...this.state}
            columns={columns}
            dataSource={users}
          />
        </Card>
      </div>
    );
  }
}

export default UserList;
