import React from "react";
import { Checkbox, Button, Form, Input } from "antd";
import { connect } from "react-redux";
import {
  userSignIn,
  getUser,
  userConfirmPasswordReset,
  userRequestPasswordReset
} from "../appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";
import TermsAndConditions from "../modals/TermsAndConditionsModal";

const FormItem = Form.Item;

class SignIn extends React.Component {
  state = {
    newPassword: "",
    password: "",
    username: "",
    forgotPassword: false,
    modalVisible: false,
    agreed: false
  };

  onSignIn() {
    const { history } = this.props;
    const { email, password } = this.state;
    const redirectTo = new URLSearchParams(this.props.location.search).get(
      "redirectTo"
    );
    this.props.userSignIn(
      {
        email,
        password
      },
      async () => {
        await this.props.getUser();
        if (!redirectTo) {
          history.push("/events");
        } else {
          history.push(redirectTo);
        }
      }
    );
  }

  onChangePassword() {
    const { match } = this.props;
    const { params } = match;
    const { passwordResetCode, UserId } = params;
    const { newPassword, confirmPassword } = this.state;
    if (newPassword === confirmPassword) {
      this.props.userConfirmPasswordReset(
        {
          passwordResetCode,
          UserId,
          newPassword
        },
        () => this.setState({ forceShowSignIn: true })
      );
    }
  }

  onRequestResetPassword() {
    const { history } = this.props;
    const { email } = this.state;
    this.props.form.validateFields(err => {
      if (!err) {
        this.props.userRequestPasswordReset(
          {
            email
          },
          history
        );
        this.setState({ passwordResetSent: true });
      }
    });
  }

  renderComponent(userColor, welcomeHeader) {
    const { match } = this.props;
    const { forgotPassword, forceShowSignIn } = this.state;
    const { params } = match;
    const { passwordResetCode } = params;

    if (passwordResetCode && !forceShowSignIn) {
      return this.renderChangePassword(userColor, welcomeHeader);
    } else if (forgotPassword && !forceShowSignIn) {
      return this.renderForgotPassword(userColor, welcomeHeader);
    }
    return this.renderLogin(userColor, welcomeHeader);
  }

  renderForgotPassword(userColor, welcomeHeader) {
    const { form } = this.props;
    const { email, passwordResetSent } = this.state;
    const { getFieldDecorator } = form;
    return (
      <>
        <div className="gx-app-logo-content">
          <div
            className="gx-app-logo-content-bg"
            style={{ backgroundColor: userColor }}
          ></div>
          <div className="gx-app-logo-wid">
            <h1>{welcomeHeader} Forgotten Password</h1>
            <p>Welcome to SmartSTEMs {welcomeHeader} portal.</p>
          </div>
          <div className="gx-app-logo">
            <img alt="example" src={require("assets/images/logo.png")} />
          </div>
        </div>
        <div className="gx-app-login-content">
          {passwordResetSent ? (
            <div style={{ paddingBottom: 15 }}>
              Password reset request link sent
            </div>
          ) : (
            <Form className="gx-signin-form gx-form-row0">
              <FormItem>
                {getFieldDecorator("email", {
                  rules: [
                    {
                      required: true,
                      type: "email",
                      message: "The input is not valid E-mail!",
                      onChange: e => this.setState({ email: e.target.value })
                    }
                  ]
                })(<Input placeholder="Email" value={email} />)}
              </FormItem>
              <FormItem>
                <Button
                  type="primary"
                  style={{ float: "right", marginRight: "5px" }}
                  className="gx-mb-0"
                  onClick={() => this.onRequestResetPassword()}
                >
                  Reset
                </Button>
                <Button
                  type=""
                  style={{
                    float: "right",
                    marginRight: "5px",
                    background: "#fff"
                  }}
                  className="gx-mb-0"
                  onClick={() => this.setState({ forgotPassword: false })}
                >
                  Cancel
                </Button>
              </FormItem>
            </Form>
          )}
          <span className="gx-text-light gx-fs-sm">
            You should receive an email with a link to reset your password
          </span>
        </div>
      </>
    );
  }

  handleCancel() {
    this.setState({ modalVisible: false });
  }

  renderChangePassword(userColor, welcomeHeader) {
    const { newPassword, confirmPassword } = this.state;
    const { match, form } = this.props;
    const { getFieldDecorator } = form;
    const { params } = match;
    const { initialLogin } = params;
    return (
      <>
        <div className="gx-app-logo-content">
          <div
            className="gx-app-logo-content-bg"
            style={{ backgroundColor: userColor }}
          ></div>
          <div className="gx-app-logo-wid">
            <h1>
              {welcomeHeader} {!!initialLogin ? "Create" : " Change"} Password
            </h1>
            <p>
              Welcome to SmartSTEMs {welcomeHeader} portal.
              {initialLogin
                ? " Since it is the first time logging in, please enter a new password."
                : " Please enter a new password"}
            </p>
          </div>
          <div className="gx-app-logo">
            <img alt="example" src={require("assets/images/logo.png")} />
          </div>
        </div>
        <div className="gx-app-login-content">
          <Form className="gx-signin-form gx-form-row0">
            <FormItem name="password" value={newPassword}>
              {getFieldDecorator("newPassword", {
                rules: [
                  {
                    required: true,
                    message: "Please enter a new password"
                  },
                  {
                    min: 8,
                    message: "Password must be minimum 8 characters."
                  },
                  {
                    pattern: new RegExp("(?=.*[a-z])"),
                    message: "Include lowercase."
                  },
                  {
                    pattern: new RegExp("(?=.*[A-Z])"),
                    message: "Include uppercase."
                  },
                  {
                    pattern: new RegExp("(?=.*[0-9])"),
                    message: "Include digits."
                  },
                  {
                    pattern: new RegExp("(?=.*[-+_!@#$£%^&*.,?])"),
                    message: "Include symbols."
                  }
                ],
                onChange: e => this.setState({ newPassword: e.target.value })
              })(<Input type="password" placeholder="New Password" />)}
            </FormItem>
            <FormItem dependencies={["password"]} value={confirmPassword}>
              {getFieldDecorator("confirmPassword", {
                rules: [
                  {
                    required: true,
                    message: "Please confirm your password"
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("newPassword") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "The two passwords that you entered do not match!"
                      );
                    }
                  })
                ],
                onChange: e =>
                  this.setState({ confirmPassword: e.target.value })
              })(<Input type="password" placeholder="Confirm Password" />)}
            </FormItem>
            {!!initialLogin && (
              <FormItem>
                {getFieldDecorator("acceptTerms", {
                  rules: [
                    {
                      required: true,
                      message: "Please accept terms and conditions to continue"
                    }
                  ]
                })(
                  <>
                    <Checkbox
                      checked={this.state.agreed}
                      onChange={() =>
                        this.setState({ agreed: !this.state.agreed })
                      }
                    />
                    <span>
                      {" "}
                      I agree to the{" "}
                      <a onClick={() => this.setState({ modalVisible: true })}>
                        terms and conditions
                      </a>
                    </span>
                  </>
                )}
              </FormItem>
            )}
            <FormItem>
              <Button
                type="primary"
                style={{ float: "right", marginRight: "5px" }}
                className="gx-mb-0"
                onClick={() => this.onChangePassword()}
              >
                Change Password
              </Button>
              <Button
                type=""
                style={{
                  float: "right",
                  marginRight: "5px",
                  background: "#fff"
                }}
                className="gx-mb-0"
                onClick={() => this.props.history.push("/signin/user")}
              >
                Cancel
              </Button>
            </FormItem>
          </Form>
        </div>
      </>
    );
  }

  renderLogin(userColor, welcomeHeader) {
    const { getFieldDecorator } = this.props.form;
    const { email, password } = this.state;
    return (
      <>
        <div
          className="gx-app-logo-content"
          style={{
            paddingTop: "35px",
            paddingRight: "10px",
            paddingBottom: "10px",
            paddingLeft: "35px"
          }}
        >
          <div
            className="gx-app-logo-content-bg"
            style={{ backgroundColor: userColor }}
          ></div>
          <div className="gx-app-logo-wid">
            <h1>{welcomeHeader} Login</h1>
            <p>Welcome to SmartSTEMs {welcomeHeader} portal.</p>
          </div>
          <div
            className="gx-app-logo"
            style={{
              paddingTop: "35px",
              paddingRight: "35px",
              paddingBottom: "10px",
              paddingLeft: "0px",
              display: "inline"
            }}
          >
            <img alt="example" src={require("assets/images/logo.png")} />
            <span
              style={{
                display: "inline",
                marginLeft: 10,
                float: "right"
              }}
            >
              {process.env.REACT_APP_VERSION}
            </span>
          </div>
        </div>
        <div className="gx-app-login-content">
          <Form className="gx-signin-form gx-form-row0">
            <FormItem value={email}>
              {getFieldDecorator("email", {
                rules: [
                  {
                    required: true,
                    type: "email",
                    message: "The input is not valid E-mail!"
                  }
                ],
                onChange: e => this.setState({ email: e.target.value })
              })(<Input placeholder="Email" />)}
            </FormItem>
            <FormItem value={password}>
              {getFieldDecorator("password", {
                rules: [
                  { required: true, message: "Please input your Password!" }
                ],
                onChange: e => this.setState({ password: e.target.value })
              })(<Input type="password" placeholder="Password" />)}
            </FormItem>
            <FormItem>
              <Button
                type="primary"
                style={{ float: "right", marginRight: 0, width: 120 }}
                className="gx-mb-0"
                onClick={() => this.onSignIn()}
                size="large"
              >
                <IntlMessages id="app.userAuth.signIn" />
              </Button>
              <a onClick={() => this.setState({ forgotPassword: true })}>
                <IntlMessages id="app.userAuth.forgotPassword" />
              </a>
            </FormItem>
            <span className="gx-text-light gx-fs-sm">
              You should have received login details from SmartSTEMs via email
            </span>
          </Form>
        </div>
      </>
    );
  }

  handleAgree() {
    this.setState({ agreed: true });
    this.handleCancel();
  }

  render() {
    let userColor = "";
    let welcomeHeader = "";
    const { match } = this.props;
    const { params } = match;
    const { userType } = params;

    switch (userType) {
      case "admin":
        userColor = "#3fc4f0";
        welcomeHeader = "Admin";
        break;
      case "fe":
        userColor = "orange";
        welcomeHeader = "Further/Higher Education";
        break;
      case "workshop":
        userColor = "#2dcd5e";
        welcomeHeader = "Workshop Host";
        break;
      case "exhibitor":
        userColor = "#f64a94";
        welcomeHeader = "Exhibitor";
        break;
      case "speaker":
        userColor = "#fbed48";
        welcomeHeader = "Speaker";
        break;
    }

    const bg = require("assets/images/main.jpg");
    return (
      <div
        className="gx-app-login-wrap"
        style={{
          backgroundImage: "url(" + bg + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover"
        }}
      >
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            {this.renderComponent(userColor, welcomeHeader)}
            <InfoView />
          </div>
        </div>
        <TermsAndConditions
          visible={this.state.modalVisible}
          handleCancel={() => this.handleCancel()}
          agreed={this.state.agreed}
          handleAgree={() => this.handleAgree()}
        />
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(SignIn);

const mapStateToProps = ({ auth }) => {
  const { token } = auth;
  return { token };
};

export default connect(mapStateToProps, {
  getUser,
  userSignIn,
  userConfirmPasswordReset,
  userRequestPasswordReset
})(WrappedNormalLoginForm);
