import React, {Component} from "react";
import {connect} from "react-redux";
import {Avatar, Popover} from "antd";
import { withRouter } from "react-router-dom";
import {userSignOut, getUser} from "appRedux/actions/Auth";
import MSLogin from "../../components/MSLogIn/MSLogin";

class UserProfile extends Component {
  render() {
    const { authUser, history } = this.props;
    const userMenuOptions = (
      <ul className="gx-user-popover">
        {(authUser && authUser.hasMSConnected) || <li><MSLogin /></li>}
        <li onClick={() => this.props.userSignOut(history)}>Logout</li>
      </ul>
    );

    return (
      <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
        <Popover
          placement="bottomRight"
          content={userMenuOptions}
          trigger="click"
        >
          <Avatar
            src={require("assets/images/placeholder.jpg")}
            className="gx-size-40 gx-pointer gx-mr-3"
            alt=""
          />
          <span className="gx-avatar-name">
            {authUser ? (authUser.firstName ? `${authUser.firstName} ${authUser.lastName}` : `${authUser.role}`) : '...'}
            <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
          </span>
        </Popover>
      </div>
    );

  }
}

const mapStateToProps = ({auth}) => {
  const {authUser} = auth;
  return {authUser}
};

export default withRouter(connect(mapStateToProps, { userSignOut, getUser })(UserProfile));
