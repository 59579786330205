import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import EventList from "./EventList";
import UserList from "./UserList";
import Schools from "./Schools";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import asyncComponent from "util/asyncComponent";
class Index extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { match, authUser } = this.props;
    const isAdminAuthenticated = authUser && authUser.role === "superadmin";
    return (
      <div className="gx-main-content-wrapper">
        <Switch>
          <Route
            path={`${match.url}eventBookings`}
            exact
            component={asyncComponent(() => import("./EventBookings"))}
          />
          <Route
            path={`${match.url}events`}
            exact
            component={asyncComponent(() => import("./EventList"))}
          />
          <Route
            path={`${match.url}:EventId/activities`}
            component={asyncComponent(() => import("./ActivityList"))}
          />
          <Route
            exact
            path={`${match.url}schools`}
            render={() => (isAdminAuthenticated ? <Schools /> : <EventList />)}
          />
          <Route
            exact
            path={`${match.url}users`}
            render={() => (isAdminAuthenticated ? <UserList /> : <EventList />)}
          />
          <Route
            path={`${match.url}event/:id`}
            component={asyncComponent(() => import("./EventDetails"))}
          />
          <Route
            path={'/event-booking/:id/:prompt?'}
            component={asyncComponent(() => import("./EventBooking"))}
          />
          <Route
            path={`${match.url}eventAgenda/:id`}
            component={asyncComponent(() => import("./EventAgenda"))}
          />
          <Route
            path={`${match.url}governanceDocs/:id`}
            component={asyncComponent(() => import("./GovernanceDocs"))}
          />
          <Route
            path={`${match.url}riskAssessment/:id`}
            component={asyncComponent(() => import("./RiskAssessment"))}
          />
          <Route
            path={`${match.url}setAgenda/:id`}
            component={asyncComponent(() => import("./SetAgenda"))}
          />
          <Route
            path={`${match.url}:EventId/activity/add/:type?`}
            component={asyncComponent(() => import("./ActivityEditor"))}
          />
          <Route
            path={`${match.url}activity/:ActivityId/edit`}
            component={asyncComponent(() => import("./ActivityEditor"))}
          />
          <Route
            path={`${match.url}activity/:ActivityId/view`}
            component={asyncComponent(() => import("./ActivityView"))}
          />
          <Route
            path={`${match.url}link`}
            component={asyncComponent(() => import("./LinkMicrosoft"))}
          />
        </Switch>
      </div>
    );
  }
}
const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser };
};

export default withRouter(connect(mapStateToProps, null)(Index));
