import React, {Component} from "react";
import {connect} from "react-redux";
import {Avatar, Popover} from "antd";
import {userSignOut} from "appRedux/actions/Auth";
import { withRouter } from "react-router-dom";

class UserInfo extends Component {

  render() {
    const { history } = this.props;
    const userMenuOptions = (
      <ul className="gx-user-popover">
        <li onClick={() => this.props.userSignOut(history)}>Logout
        </li>
      </ul>
    );

    return (
      <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions}
               trigger="click">
        <Avatar src='/assets/images/placeholder.jpg'
                className="gx-avatar gx-pointer" alt=""/>
      </Popover>
    )

  }
}

export default withRouter(connect(null, { userSignOut })(UserInfo));
