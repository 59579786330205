import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
} from "../../constants/ActionTypes";
import { API_URL } from "../../constants/config";
import axios from 'util/Api';

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const userSignIn = ({ email, password }, onComplete) => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios
      .post(`${API_URL}/login`, {
        email: email,
        password: password
      })
      .then(({ data }) => {
        if (data) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: USER_DATA, payload: data });
          onComplete();
        } else {
          console.log("payload: data.error", data.error);
          dispatch({ type: FETCH_ERROR, payload: "Network Error" });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: 'Unable to login. Incorrect email or password.' });
        console.log("Error****:", error.message);
      });
  };
};

export const userPasswordChange = ({ newPassword }, history) => {
  console.log(newPassword)
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios
      .put(`${API_URL}/user/password`, {
        newPassword,
      })
      .then(({ data }) => {
        if (data) {
          history.push("/events");
        } else {
          console.log("payload: data.error", data.error);
          dispatch({ type: FETCH_ERROR, payload: "Network Error" });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const userConfirmPasswordReset = ({ UserId, newPassword, passwordResetCode }, onComplete) => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios
      .put(`${API_URL}/reset/confirm`, {
        password: newPassword,
        passwordResetCode,
        UserId,
      })
      .then(({ data }) => {
        dispatch({ type: FETCH_SUCCESS });
        if (onComplete) {
          onComplete();
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const userRequestPasswordReset = ({ email }, history) => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios
      .put(`${API_URL}/reset/request`, {
        email,
      })
      .then(({ data }) => {
        dispatch({ type: FETCH_SUCCESS });
        history.push("/signin/user/reset");
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const userSignUp = ({email, password, name}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('auth/register', {
        email: email,
        password: password,
        name: name
      }
    ).then(({data}) => {
      console.log("data:", data);
      if (data.result) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: USER_DATA, payload: data.user});
      } else {
        console.log("payload: data.error", data.error);
        dispatch({type: FETCH_ERROR, payload: "Network Error"});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const getUser = (onComplete, onFail) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.get('user'
    ).then(({data}) => {
      console.log("userSignIn: ", data);
      if (data) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: USER_DATA, payload: data});
        onComplete && onComplete(data);
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
        onFail && onFail();
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
      onFail && onFail();
    });
  }
};


export const userSignOut = (history) => {
  console.log('called')
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('logout'
    ).then(({ data }) => {
      console.log("userLogOut: ", data);
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: SIGNOUT_USER_SUCCESS });
      history.push('/signin/user');
    }).catch(function (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      console.log("Error****:", error.message);
    });
  }
};
