import React, { Component } from "react";
import {
  DatePicker,
  Form,
  Input,
  Select,
  TimePicker,
  Modal,
  Checkbox,
} from "antd";
import moment from "moment";
import axios from "util/Api";
import { API_URL } from "../constants/config";

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    xs: { span: 20 },
    sm: { span: 10 },
    md: { span: 10 },
    lg: { span: 30 }
  },
  wrapperCol: {
    xs: { span: 30 },
    sm: { span: 12 },
    md: { span: 30 },
    lg: { span: 30 }
  }
};
class CreateEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      confirmLoading: false,
      checkedValues: [],
      audienceAge: '',
      SmartSTEMsUserId: null,
    };
  }
  componentDidMount() {
    const { record } = this.props;
    this.getUsers();
    this.setState({ audienceAge: record.audienceAge || '', SmartSTEMsUserId: record.SmartSTEMsUserId })
  }

  getUsers = () => {
    return axios
      .get("user/search", {
        params: {
          role: "superadmin"
        }
      })
      .then(users => {
        if (users.data) {
          this.setState({ users: users.data });
        } else {
        }
      })
      .catch(function(error) {
        console.log("Error****:", error.message);
      });
  };

  renderUserDropdown = () => {
    const { users, SmartSTEMsUserId } = this.state;
    if (users.length) {
      return (
        <Select
          placeholder="Select SmartSTEMs Contact"
          onChange={(SmartSTEMsUserId) => this.setState({ SmartSTEMsUserId })}
          value={SmartSTEMsUserId}
        >
          {users &&
            users.map((user) => (
              <Option
                value={user.id}
              >{`${user.firstName} ${user.lastName}`}</Option>
            ))}
        </Select>
      );
    }
  };

  createEvent = values => {
    const { handleCancel, getData } = this.props;
    const { audienceAge, SmartSTEMsUserId, users } = this.state;
    const {
      name,
      categories,
      audienceGender,
      lunchDetails,
      twitterHandles,
      twitterHashtags,
      address,
      host,
      hostLocation,
      isPrivate,
      clientType,
      partnerEmail,
      capacity,
      eventDetails,
      studentGuidesDietaryRequirements,
      numberOfStudentGuides,
      targetNumberOfTeachers,
      targetNumberOfPupils,
      targetNumberOfInternalWorkshops,
      targetNumberOfExternalWorkshops,
      targetNumberOfInternalExhibitors,
      targetNumberOfExternalExhibitors,
      targetNumberOfSpeakers,
      targetNumberOfSchools,
      targetNumberOfGuides,
      organisation,
      role,
      multimediaContactName,
      multimediaContactPhone,
      multimediaContactEmail,
      cateringContactName,
      instagramHandle,
      facebookUrl,
      startDate,
      endDate,
      startTime,
      endTime,
    } = values;
    const startDateTime = moment(
      `${startDate.format("YYYY-MM-DD")} ${startTime.format("HH:mm:ss")}`,
      "YYYY-MM-DD HH:mm:ss"
    ).format();
    const endDateTime = moment(
      `${endDate.format("YYYY-MM-DD")} ${endTime.format("HH:mm:ss")}`,
      "YYYY-MM-DD HH:mm:ss"
    ).format();
    const data = {
      name,
      categories: categories.toString(),
      startDateTime,
      endDateTime,
      audienceAge,
      audienceGender,
      SmartSTEMsUserId,
      lunchDetails,
      twitterHandles,
      twitterHashtags,
      address,
      host,
      hostLocation,
      isPrivate,
      clientType,
      partnerEmail,
      capacity,
      eventDetails,
      studentGuidesDietaryRequirements,
      numberOfStudentGuides,
      targetNumberOfTeachers,
      targetNumberOfPupils,
      targetNumberOfInternalWorkshops,
      targetNumberOfExternalWorkshops,
      targetNumberOfInternalExhibitors,
      targetNumberOfExternalExhibitors,
      targetNumberOfSpeakers,
      targetNumberOfSchools,
      targetNumberOfGuides,
      organisation,
      role,
      multimediaContactName,
      multimediaContactPhone,
      multimediaContactEmail,
      cateringContactName,
      instagramHandle,
      facebookUrl,
    };
    return axios
      .post(`${API_URL}/event`, data)
      .then((res) => {
        console.log(res)
        this.props.form.resetFields();
        handleCancel();
        getData();
      })
      .catch(function(error) {
        console.log("Error****:", error.message);
      });
  };

  editEvent = values => {
    const { handleCancel, record, getData } = this.props;
    const { audienceAge, SmartSTEMsUserId, users } = this.state;
    const {
      name,
      categories,
      startDate,
      endDate,
      startTime,
      endTime,
      audienceGender,
      lunchDetails,
      twitterHandles,
      twitterHashtags,
      address,
      host,
      hostLocation,
      isPrivate,
      clientType,
      partnerEmail,
      capacity,
      eventDetails,
      studentGuidesDietaryRequirements,
      numberOfStudentGuides,
      targetNumberOfTeachers,
      targetNumberOfPupils,
      targetNumberOfInternalWorkshops,
      targetNumberOfExternalWorkshops,
      targetNumberOfInternalExhibitors,
      targetNumberOfExternalExhibitors,
      targetNumberOfSpeakers,
      targetNumberOfSchools,
      targetNumberOfGuides,
      organisation,
      role,
      multimediaContactName,
      multimediaContactPhone,
      multimediaContactEmail,
      cateringContactName,
      instagramHandle,
      facebookUrl,
    } = values;
    const startDateTime = moment(
      `${startDate.format("YYYY-MM-DD")} ${startTime.format("HH:mm:ss")}`,
      "YYYY-MM-DD HH:mm:ss"
    ).format();
    const endDateTime = moment(
      `${endDate.format("YYYY-MM-DD")} ${endTime.format("HH:mm:ss")}`,
      "YYYY-MM-DD HH:mm:ss"
    ).format();
    const data = {
      id: record.id,
      name,
      categories: categories.toString(),
      startDateTime,
      endDateTime,
      audienceAge,
      audienceGender,
      SmartSTEMsUserId,
      lunchDetails,
      twitterHandles,
      twitterHashtags,
      address,
      host,
      hostLocation,
      isPrivate,
      clientType,
      partnerEmail,
      capacity,
      eventDetails,
      studentGuidesDietaryRequirements,
      numberOfStudentGuides,
      targetNumberOfTeachers,
      targetNumberOfPupils,
      targetNumberOfInternalWorkshops,
      targetNumberOfExternalWorkshops,
      targetNumberOfInternalExhibitors,
      targetNumberOfExternalExhibitors,
      targetNumberOfSpeakers,
      targetNumberOfSchools,
      targetNumberOfGuides,
      organisation,
      role,
      multimediaContactName,
      multimediaContactPhone,
      multimediaContactEmail,
      cateringContactName,
      instagramHandle,
      facebookUrl,
    };
    return axios
      .put(`${API_URL}/event`, data)
      .then(({ res }) => {
        if (res) {
          this.props.form.resetFields();
          handleCancel();
          getData();
        } else {
          this.props.form.resetFields();
          handleCancel();
          getData();
        }
      })
      .catch(function(error) {
        console.log("Error****:", error.message);
      });
  };

  handleSubmit(e) {
    const { edit, form } = this.props;
    const { submitted } = this.state;
    this.setState({ confirmLoading: true });
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err && submitted) {
        edit ? this.editEvent(values) : this.createEvent(values);
      }
    });
    this.setState({ confirmLoading: false, submitted: false });

  };

  renderTitle(title) {
    return (
      <div style={{ color: "#44A6DE", fontSize: 17, paddingLeft: 10 }}>
        {title}
      </div>
    );
  }

  onConfirmCancel() {
    const { handleCancel, form } = this.props;
    form.resetFields();
    handleCancel();
  }

  onHandleCancel() {
    this.setState({ showConfirmClose: true });
  }

  onChange(checkedValues) {
    this.setState({ checkedValues });
  }

  render() {
    const { visible, form, edit, record } = this.props;
    const { getFieldDecorator } = form;
    const { showConfirmClose, confirmLoading, audienceAge, SmartSTEMsUserId, users } = this.state;
    const categoryArray = record.categories && record.categories.split(",");
    const options = [
      { label: "8-10", value: "8-10"},
      { label: "11-12", value: "11-12" },
      { label: "13-14", value: "13-14" },
      { label: "15-16", value: "15-16" }
    ];
    const parsedAudienceAge = audienceAge.split(',');
    return (
      <>
        <Modal
          okButtonProps={{
            key: "submit",
            htmlType: "submit"
          }}
          onOk={() => this.onConfirmCancel()}
          maskClosable={false}
          title={"Dismiss?"}
          confirmLoading={confirmLoading}
          visible={showConfirmClose}
          okText={"Yes, Im sure"}
          onCancel={() => this.setState({ showConfirmClose: false })}
          width={450}
        >
          Are you sure you want to close this? Any unsaved changes will be lost.
        </Modal>
        <Modal
          okButtonProps={{
            form: "eventForm",
            key: "submit",
            htmlType: "submit"
          }}
          disabled={confirmLoading}
          maskClosable={false}
          title={edit ? `Edit Event` : `Create Event`}
          visible={visible}
          okText={edit ? `Save Event` : `Go live`}
          confirmLoading={confirmLoading}
          onCancel={() => this.onHandleCancel()}
          width={900}
        >
          <Form
            form={form}
            onSubmit={(e) => {
              e.preventDefault();
              this.setState({ submitted: true }, () =>
              this.handleSubmit(e))
            }}
            id="eventForm"
          >
            {this.renderTitle("Details")}
            <br />
            <FormItem {...formItemLayout} label="Event Name">
              {getFieldDecorator("name", {
                initialValue: edit ? record.name : "",
                rules: [
                  { required: true, message: "Please enter an event name" }
                ]
              })(<Input placeholder="" />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Event Details">
              {getFieldDecorator("eventDetails", {
                initialValue: edit ? record.eventDetails : "",
                rules: [
                  { required: true, message: "Please enter an event details" }
                ]
              })(<TextArea placeholder="" />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Categories">
              {getFieldDecorator("categories", {
                initialValue: edit ? categoryArray : [],
                rules: [
                  {
                    required: true,
                    message: "Please select one or more categories",
                    type: "array"
                  }
                ]
              })(
                <Select
                  mode="multiple"
                  placeholder="Select one or more categories"
                >
                  <Option value="Science">Science</Option>
                  <Option value="Technology">Technology</Option>
                  <Option value="Engineering">Engineering</Option>
                  <Option value="Maths">Maths</Option>
                  <Option value="Other">Other</Option>
                </Select>
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="Date (Start - End)">
              <div className="ant-row gx-form-row0">
                <FormItem style={{ paddingRight: 10, paddingLeft: 15 }}>
                  {getFieldDecorator("startDate", {
                    initialValue: edit ? moment(record.startDateTime) : "",
                    rules: [
                      { required: true, message: "Please enter a start date" }
                    ]
                  })(<DatePicker />)}
                </FormItem>
                -
                <FormItem style={{ paddingRight: 10, paddingLeft: 10 }}>
                  {getFieldDecorator("endDate", {
                    initialValue: edit ? moment(record.endDateTime) : "",
                    rules: [
                      { required: true, message: "Please enter an end date" }
                    ]
                  })(<DatePicker />)}
                </FormItem>
              </div>
            </FormItem>

            <FormItem {...formItemLayout} label="Time (Start - End)">
              <div className="ant-row gx-form-row0">
                <FormItem style={{ paddingRight: 10, paddingLeft: 15 }}>
                  {getFieldDecorator("startTime", {
                    initialValue: edit ? moment(record.startDateTime) : "",
                    rules: [
                      { required: true, message: "Please enter a start time" }
                    ]
                  })(
                    <TimePicker
                      minuteStep={5}
                      format={"HH:mm"}
                      style={{ width: "100%" }}
                    />
                  )}
                </FormItem>
                -
                <FormItem style={{ paddingRight: 10, paddingLeft: 10 }}>
                  {getFieldDecorator("endTime", {
                    initialValue: edit ? moment(record.endDateTime) : "",
                    rules: [
                      { required: true, message: "Please enter an end time" }
                    ]
                  })(
                    <TimePicker
                      minuteStep={5}
                      format={"HH:mm"}
                      style={{ width: "100%" }}
                    />
                  )}
                </FormItem>
              </div>
            </FormItem>
            <FormItem {...formItemLayout} label="Lunch Details">
              {getFieldDecorator("lunchDetails", {
                initialValue: edit ? record.lunchDetails : "",
                rules: [
                  { required: false, message: "Please enter an lunch details" }
                ]
              })(<TextArea placeholder="" />)}
            </FormItem>
            {this.renderTitle("Location")}
            <br />
            <FormItem {...formItemLayout} label="Host">
              {getFieldDecorator("host", {
                initialValue: edit ? record.host : "",
                rules: [{ required: true, message: "Please enter a host" }]
              })(<Input placeholder="e.g. UWS" />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Host Location">
              {getFieldDecorator("hostLocation", {
                initialValue: edit ? record.hostLocation : "",
                rules: [
                  { required: true, message: "Please enter a host location" }
                ]
              })(<Input placeholder="e.g. UWS Paisley Campus" />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Address">
              {getFieldDecorator("address", {
                initialValue: edit ? record.address : "",
                rules: [{ required: true, message: "Please enter an address" }]
              })(<Input placeholder="e.g. High St, Paisley PA1 2BE" />)}
            </FormItem>
            <br />
            {this.renderTitle("Contacts")}
            <br />
            <FormItem {...formItemLayout} label="Client Type">
              {getFieldDecorator("clientType", {
                initialValue: edit ? record.clientType : "",
                rules: [
                  { required: true, message: "Please select client type" }
                ]
              })(
                <Select placeholder="Select client type">
                  <Option value="Education">Education</Option>
                  <Option value="Corporate">Corporate</Option>
                  <Option value="Other">Other</Option>
                </Select>
              )}
            </FormItem>
            {!edit && (
              <FormItem {...formItemLayout} label="Host Email">
                {getFieldDecorator("partnerEmail", {
                  initialValue: edit ? record.partnerEmail : "",
                  rules: [
                    {
                      type: "email",
                      required: true,
                      message: "Please enter a valid email"
                    }
                  ]
                })(
                  <Input placeholder="Login details will be sent to this email" />
                )}
              </FormItem>
            )}

            <FormItem {...formItemLayout} label="SmartSTEMs Contact">
              {getFieldDecorator("SmartSTEMsUserId", {
                initialValue: SmartSTEMsUserId,
                rules: [
                  {
                    required: true,
                    message: "Please select SmartSTEMs contact"
                  }
                ]
              })(
                <Select
                  placeholder="Select SmartSTEMs Contact"
                  onChange={SmartSTEMsUserId =>
                    this.setState({ SmartSTEMsUserId })
                  }
                  value={SmartSTEMsUserId}
                >
                  {users &&
                    users.map(user => (
                      <Option
                        value={user.id}
                      >{`${user.firstName} ${user.lastName}`}</Option>
                    ))}
                </Select>
              )}
            </FormItem>
            <br />
            {this.renderTitle("Audience")}
            <br />
            <FormItem {...formItemLayout} label="Audience Age">
              <Checkbox.Group
                options={options}
                onChange={e => {
                  this.setState({ audienceAge: e.toString() });
                }}
                value={parsedAudienceAge}
              />
            </FormItem>

            <FormItem {...formItemLayout} label="Audience Gender">
              {getFieldDecorator("audienceGender", {
                initialValue: edit ? record.audienceGender : "",
                rules: [
                  { required: false, message: "Please select audience gender" }
                ]
              })(
                <Select placeholder="Select audience gender">
                  <Option value="Male">Male</Option>
                  <Option value="Female">Female</Option>
                  <Option value="Male & Female">Male & Female</Option>
                  <Option value="Other">Other</Option>
                </Select>
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="Capacity">
              {getFieldDecorator("capacity", {
                initialValue: edit ? record.capacity : "",
                rules: [
                  { required: true, message: "Please enter the capacity" }
                ]
              })(<Input placeholder="e.g. 100" id="capacity" />)}
            </FormItem>
            <br />
            {this.renderTitle("KPIs")}
            <br />
            <FormItem {...formItemLayout} label="Schools Target">
              {getFieldDecorator("targetNumberOfSchools", {
                initialValue: edit ? record.targetNumberOfSchools : "",
                rules: [
                  { required: true, message: "Please enter a schools target" }
                ]
              })(
                <Input
                  placeholder=""
                  id="targetNumberOfSchools"
                  style={{ width: 150 }}
                />
              )}
            </FormItem>
            <FormItem {...formItemLayout} label="Pupils Target">
              {getFieldDecorator("targetNumberOfPupils", {
                initialValue: edit ? record.targetNumberOfPupils : "",
                rules: [
                  { required: true, message: "Please enter a pupils target" }
                ]
              })(
                <Input
                  placeholder=""
                  id="targetNumberOfPupils"
                  style={{ width: 150 }}
                />
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="Student Guides Target">
              {getFieldDecorator("targetNumberOfGuides", {
                initialValue: edit ? record.targetNumberOfGuides : "",
                rules: [
                  { required: true, message: "Please enter a guides target" }
                ]
              })(
                <Input
                  placeholder=""
                  id="targetNumberOfGuides"
                  style={{ width: 150 }}
                />
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="Host Workshops Target">
              {getFieldDecorator("targetNumberOfInternalWorkshops", {
                initialValue: edit
                  ? record.targetNumberOfInternalWorkshops
                  : "",
                rules: [
                  { required: true, message: "Please enter a workshops target" }
                ]
              })(
                <Input
                  placeholder=""
                  id="targetNumberOfInternalWorkshops"
                  style={{ width: 150 }}
                />
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="Total Workshops Target">
              {getFieldDecorator("targetNumberOfExternalWorkshops", {
                initialValue: edit
                  ? record.targetNumberOfExternalWorkshops
                  : "",
                rules: [
                  { required: true, message: "Please enter a workshops target" }
                ]
              })(
                <Input
                  placeholder=""
                  id="targetNumberOfExternalWorkshops"
                  style={{ width: 150 }}
                />
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="Exhibitor Target">
              {getFieldDecorator("targetNumberOfInternalExhibitors", {
                initialValue: edit
                  ? record.targetNumberOfInternalExhibitors
                  : "",
                rules: [
                  {
                    required: true,
                    message: "Please enter an exhibitors target"
                  }
                ]
              })(
                <Input
                  placeholder=""
                  id="targetNumberOfInternalExhibitors"
                  style={{ width: 150 }}
                />
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="Speaker Target">
              {getFieldDecorator("targetNumberOfSpeakers", {
                initialValue: edit ? record.targetNumberOfSpeakers : "",
                rules: [
                  { required: true, message: "Please enter a speakers target" }
                ]
              })(
                <Input
                  placeholder=""
                  id="targetNumberOfSpeakers"
                  style={{ width: 150 }}
                />
              )}
            </FormItem>
            <br />
            {this.renderTitle("Social Media")}
            <br />
            <FormItem {...formItemLayout} label="Twitter Handles">
              {getFieldDecorator("twitterHandles", {
                initialValue: edit ? record.twitterHandles : "",
                rules: [
                  { required: false, message: "Please enter an event name" }
                ]
              })(<Input placeholder="e.g. @SmartSTEMs, @UniveristyName" />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Hashtags">
              {getFieldDecorator("twitterHashtags", {
                initialValue: edit ? record.twitterHashtags : "",
                rules: [
                  { required: false, message: "Please enter an event name" }
                ]
              })(<Input placeholder="e.g. #stem, #education" />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Instagram Handles">
              {getFieldDecorator("instagramHandle", {
                initialValue: edit ? record.instagramHandle : "",
                rules: [
                  { required: false, message: "Please enter an event name" }
                ]
              })(<Input placeholder="e.g. @SmartSTEMs, @UniveristyName" />)}
            </FormItem>
            <FormItem {...formItemLayout} label="Facebook Page URL">
              {getFieldDecorator("facebookURL", {
                initialValue: edit ? record.facebookURL : "",
                rules: [
                  { required: false, message: "Please enter an event name" }
                ]
              })(
                <Input placeholder="e.g. https://www.facebook.com/smartstems/" />
              )}
            </FormItem>

            <FormItem {...formItemLayout} label="Private event?">
              {getFieldDecorator("isPrivate", {
                initialValue: edit ? record.isPrivate : false,
                rules: [{ required: false, message: "Please enter an address" }]
              })(<Checkbox></Checkbox>)}
            </FormItem>
          </Form>
        </Modal>
      </>
    );
  }
}

const CreateEventModal = Form.create()(CreateEvent);
export default CreateEventModal;
