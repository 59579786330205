import React, { Component } from "react";
import { connect } from "react-redux";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";

import Auxiliary from "util/Auxiliary";
import UserProfile from "./UserProfile";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";

class SidebarContent extends Component {
  getNoHeaderClass = navStyle => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  getNavStyleSubMenuClass = navStyle => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  render() {
    const { themeType, navStyle, pathname, authUser } = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split("/")[1];
    return (
      <Auxiliary>
        <SidebarLogo />
        <div className="gx-sidebar-content">
          <div
            style={{
              backgroundColor: "#35C3F2",
              margin: 0,
              paddingTop: "30px",
              paddingRight: "30px",
              paddingBottom: "20px",
              paddingLeft: "30px",
              border: 0
            }}
            className={`gx-sidebar-notifications ${this.getNoHeaderClass(
              navStyle
            )}`}
          >
            <UserProfile />
          </div>
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
              mode="inline"
            >
              <Menu.Item
                style={{
                  backgroundColor: "#45B865",
                  height: "60px",
                  display: "flex",
                  alignItems: "center"
                }}
                key="Events"
              >
                <Link to="/events">
                  <i className="icon icon-calendar" />
                  <IntlMessages id="sidebar.events" />
                </Link>
              </Menu.Item>
              {authUser && authUser.role === "superadmin" && (
                <Menu.Item
                  style={{
                    backgroundColor: "#4F66AF",
                    height: "60px",
                    display: "flex",
                    alignItems: "center"
                  }}
                  key="Bookings"
                >
                  <Link to="/eventBookings">
                    <i className="icon icon-calendar" />
                    <IntlMessages id="sidebar.bookings" />
                  </Link>
                </Menu.Item>
              )}
              {authUser && authUser.role === "superadmin" && (
                <Menu.Item
                  style={{
                    backgroundColor: "#EB549F",
                    height: "60px",
                    display: "flex",
                    alignItems: "center"
                  }}
                  key="Schools"
                >
                  <Link to="/schools">
                    <i className="icon icon-company" />
                    <IntlMessages id="sidebar.schools" />
                  </Link>
                </Menu.Item>
              )}
              {authUser && authUser.role === "superadmin" && (
                <Menu.Item
                  style={{
                    backgroundColor: "#EEEB53",
                    height: "60px",
                    display: "flex",
                    alignItems: "center"
                  }}
                  key="Users"
                >
                  <Link
                    style={{
                      color: "#595959"
                    }}
                    to="/users"
                  >
                    <i className="icon icon-contacts" />
                    <IntlMessages id="sidebar.users" />
                  </Link>
                </Menu.Item>
              )}
            </Menu>
          </CustomScrollbars>
        </div>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({ settings, auth }) => {
  const { navStyle, themeType, locale, pathname } = settings;
  const { authUser } = auth;

  return { navStyle, themeType, locale, pathname, authUser };
};
export default connect(mapStateToProps)(SidebarContent);
