import React, { Component } from "react";
import {
  Form,
  Input,
  Select,
  Modal,
} from "antd";
import axios from "util/Api";
import { API_URL } from "../constants/config";

const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
  labelCol: {
    xs: { span: 20 },
    sm: { span: 10 },
    md: { span: 10 },
    lg: { span: 30 }
  },
  wrapperCol: {
    xs: { span: 30 },
    sm: { span: 12 },
    md: { span: 30 },
    lg: { span: 30 }
  }
};
class CreateUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      confirmLoading: false
    };
  }
  componentDidMount() {
    this.getUsers();
  }

  getUsers = () => {
    return axios
      .get("user/search", {
        params: {
          role: "superadmin"
        }
      })
      .then(users => {
        if (users.data) {
          this.setState({ users: users.data });
        } else {
        }
      })
      .catch(function(error) {
        console.log("Error****:", error.message);
      });
  };

  renderUserDropdown = () => {
    const { users } = this.state;
    if (users.length) {
      return (
        <Select placeholder="Select SmartSTEMs Contact">
          {users &&
            users.map((user, index) => (
              <Option
                value={index}
              >{`${user.firstName} ${user.lastName}`}</Option>
            ))}
        </Select>
      );
    }
  };

  createUser = values => {
    const {
      EventId,
      userType,
      handleCancel,
      getData,
      adminUser,
    } = this.props;
    const { firstName, lastName, email, organisation, role, contactPhone } = values;
    const data = {
      firstName,
      lastName,
      email,
      contactPhone,
      role: userType || role,
      organisation,
      EventId,
      type: role || 'Workshop',
    };
    return axios
      .post(`${API_URL}/user`, data)
      .then((res) => {
        // TO DO: Backward compatibility
        // const UserId = res.data.id || res.data.user.id;
        // if (!adminUser || res.data.user.id) {
        // this.assignUser(UserId);
      // } else {
        this.props.form.resetFields();
        handleCancel();
      // }
      getData && getData();
      })
      .catch(function(error) {
        console.log("Error****:", error.message);
      });
  };

  assignUser = UserId => {
    const { handleCancel, EventId } = this.props;
    console.log(UserId)
    const data = {
      UserId,
      EventId
    };
    return axios
      .post(`${API_URL}/user/event`, data)
      .then(({ res }) => {
        this.props.form.resetFields();
        handleCancel();
      })
      .catch(function(error) {
        console.log("Error****:", error.message);
      });
  };

  editUser = values => {
    const { handleCancel, record, role, getData } = this.props;
    const { firstName, lastName, email, contactPhone } = values;
    const data = {
      id: record.id,
      firstName,
      lastName,
      contactPhone,
      email,
      role,
    };
    return axios
      .put(`${API_URL}/user`, data)
      .then(({ res }) => {
        if (res) {
          this.props.form.resetFields();
          getData();
          handleCancel();
        } else {
          this.props.form.resetFields();
          getData();
          handleCancel();
        }
      })
      .catch(function(error) {
        console.log("Error****:", error.message);
      });
  };

  handleSubmit = e => {
    this.setState({ confirmLoading: true });
    const { edit } = this.props;
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        edit ? this.editUser(values) : this.createUser(values);
      }
    });
       this.setState({
         visible: false,
         confirmLoading: false
       });
  };

  renderTitle(title) {
    return (
      <div style={{ color: "#44A6DE", fontSize: 17, paddingLeft: 10 }}>
        {title}
      </div>
    );
  }

  onHandleCancel() {
    const { handleCancel, form } = this.props;
    form.resetFields();

    handleCancel();
  }

  render() {
    const { visible, form, edit, record, userType } = this.props;
    const { getFieldDecorator } = form;
    const { confirmLoading } = this.state;
    return (
      <Modal
        okButtonProps={{
          form: "userForm",
          key: "submit",
          htmlType: "submit"
        }}
        title={edit ? `Edit User` : `Create User`}
        visible={visible}
        okText={edit ? `Save User` : `Create User`}
        confirmLoading={confirmLoading}
        onCancel={() => this.onHandleCancel()}
        width={"90%"}
        maskClosable={false}
      >
        <Form form={form} onSubmit={this.handleSubmit} id="userForm">
          {userType !== "superadmin" && (
            <FormItem {...formItemLayout} label="Role">
              {getFieldDecorator("role", {
                initialValue: edit ? record.role : "",
                rules: [{ required: true, message: "Please select role" }]
              })(
                <Select placeholder="Select role">
                  <Option value="workshop">Workshop Host</Option>
                  <Option value="speaker">Speaker</Option>
                  <Option value="exhibitor">Exhibitor</Option>
                </Select>
              )}
            </FormItem>
          )}
          <FormItem {...formItemLayout} label="First Name">
            {getFieldDecorator("firstName", {
              initialValue: edit ? record.firstName : "",
              rules: [{ required: true, message: "Please enter first name" }]
            })(<Input placeholder="" />)}
          </FormItem>
          <FormItem {...formItemLayout} label="Last Name">
            {getFieldDecorator("lastName", {
              initialValue: edit ? record.lastName : "",
              rules: [{ required: true, message: "Please enter last name" }]
            })(<Input placeholder="" />)}
          </FormItem>
          <FormItem {...formItemLayout} label="Email">
            {getFieldDecorator("email", {
              initialValue: edit ? record.email : "",
              rules: [
                {
                  type: "email",
                  required: true,
                  message: "Please enter a valid email"
                }
              ]
            })(<Input placeholder="" />)}
          </FormItem>
          <FormItem {...formItemLayout} label="Contact Number">
            {getFieldDecorator("contactPhone", {
              initialValue: edit ? record.contactPhone : "",
              rules: [
                {
                  required: true,
                  message: "Please enter a contact number"
                }
              ]
            })(<Input placeholder="" />)}
          </FormItem>
          {userType !== "superadmin" && (
            <FormItem {...formItemLayout} label="Organisation">
              {getFieldDecorator("organisation", {
                initialValue: edit ? record.organisation : "",
                rules: [
                  { required: true, message: "Please enter the organisation" }
                ]
              })(<Input placeholder="" />)}
            </FormItem>
          )}
        </Form>
      </Modal>
    );
  }
}

const CreateUserModal = Form.create()(CreateUser);
export default CreateUserModal;
