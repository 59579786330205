import React, { Component } from "react";
import {
  Table,
  Form,
  Input,
  Select,
  TimePicker,
  Modal,
  Button,
  Divider
} from "antd";
import axios from "util/Api";
import { API_URL } from "../constants/config";
import DynamicFields from "./DynamicFields";
import moment from "moment";

const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
  labelCol: {
    xs: { span: 15 },
    sm: { span: 10 }
  },
  wrapperCol: {
    xs: { span: 15 },
    sm: { span: 15 },
    md: { span: 16 },
    lg: { span: 12 }
  }
};
class TermsAndConditions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmLoading: false,
    };
  }

  renderTitle(title) {
    return (
      <div style={{ color: "#44A6DE", fontSize: 17, paddingLeft: 10 }}>
        {title}
      </div>
    );
  }

  onHandleCancel() {
    const { handleCancel } = this.props;
    handleCancel();
  }

  render() {
    const { visible, handleAgree, agreed } = this.props;
    const { confirmLoading } = this.state;
    return (
      <Modal
        maskClosable={false}
        title={`Terms & Conditions`}
        visible={visible}
        okText={`I Agree`}
        confirmLoading={confirmLoading}
        onCancel={() => this.onHandleCancel()}
        onOk={() => handleAgree()}
        width={900}
        bodyStyle={{
          height: "60vh",
          padding: 0
        }}
      >
        <div style={{ height: "60vh", flex: 1, overflow: "auto", padding: 25 }}>
          <h4>1. Who we are:</h4>
          <p>This is the privacy statement of SmartSTEMs.</p>
          <br />
          <h4>2. Types of personal information we collect</h4>
          <p>
            We may collect, use and store different types of personal
            information about you, which we have grouped together as follows:
          </p>
          <br />
          <div style={{ textAlign: "center" }}>
            <img
              alt="..."
              src={require("assets/images/table-1.png")}
              style={{ width: "80vh" }}
            />
          </div>
          <br />
          <br />
          <h4>3. How we use your personal information</h4>
          <p>
            In general terms, we collect and use personal information to:
            <ul>
              <br />
              <li>deliver our services and meet our legal responsibilities</li>
              <br />
              <li>verify your identity where this is required</li>
              <br />
              <li>
                contact you by post, email or telephone • understand your needs
                and how they may be met
              </li>
              <br />
              <li>
                maintain our records • prevent and detect crime, fraud or
                corruption
              </li>
            </ul>
            <br />
            More specifically, the table below explains how we use your personal
            information and the reasons that we rely on in doing so. Where these
            reasons include legitimate interests, we explain what these
            legitimate interests are.
            <br />
            <br />
            <div style={{ textAlign: "center" }}>
              <img
                alt="..."
                src={require("assets/images/table-2.png")}
                style={{ width: "80vh" }}
              />
            </div>
            <br />
          </p>
          <br />
          <h4>4. Where we collect your personal information from</h4>
          <p>
            We may collect personal information about you (or your business)
            from the following sources:
            <br />
            <br />
            <ul>
              <li>Data you give to us</li>
              <br />
              <li>Data we collect when you use our services</li>
              <br />
              <li>Data from third parties that we work with</li>
            </ul>
          </p>
          <br />
          <h4>5. Who we share your information with:</h4>
          <p>
            We may share your personal information with the following third
            parties:
            <ul>
              <br />
              <li>
                Local law enforcement, councils and child protection authorities
              </li>
              <br />
              <li>Industry partners and educational establishments</li>
            </ul>
          </p>
          <br />
          <h4>6. Marketing</h4>
          <p>
            SmartSTEMs utilise social media for marketing purposes. At our
            events we do take photos and we do share on social media. Should you
            wish to opt-out of any marketing activity, please contact us at
            hello@smartstems.org
          </p>
          <br />
          <h4>7. If you choose not to give your personal information</h4>
          <p>
            If you choose not to give your personal information, it may delay or
            prohibit us from meeting our obligations / lead to us cancelling the
            service you have with us.
          </p>
          <br />
          <h4>8. How long we keep your personal information</h4>
          <p>
            “Personal data shall be kept in a form which permits identification
            of data subjects for no longer than is necessary for the purposes
            for which the personal data are processed; Personal data may be
            stored for longer periods insofar as the personal data will be
            processed solely for archiving purposes in the public interest,
            scientific or historical research purposes or statistical purposes
            in accordance with Article 89(1) subject to implementation of the
            appropriate technical and organisational measures required by this
            Regulation in order to safeguard the rights and freedoms of the data
            subject (‘storage limitation’)”.
          </p>
          <br />
          <h4>9. Using our website</h4>
          <p>
            If you continue to browse and use our website, you are agreeing to
            comply with and be bound by the following terms and conditions of
            use, which together with our privacy policy, govern SmartSTEMs,
            relationship with you in relation to this website. If you disagree
            with any part of these terms and conditions, please do not use our
            website.
          </p>
          <br />
          <h4>9.1 Overview</h4>
          <p>
            <ul>
              <li> This website is operated by SmartSTEMs.</li>
              <br />
              <li>
                Throughout the site, the terms “we”, “us” and “our” refer to
                SmartSTEMs. SmartSTEMs offers this website, including all
                information, tools and services available from this site to you,
                the user, conditioned upon your acceptance of all terms,
                conditions, policies and notices stated here.
              </li>
              <br />
              <li>
                By visiting our site you engage in our “Service” and agree to be
                bound by all of our terms and conditions (“Terms of Service”,
                “Terms”), including those additional terms and conditions and
                policies referenced herein and/or available by hyperlink. These
                Terms of Service apply to all users of the site, including
                without limitation users who are browsers, vendors, customers,
                merchants, and/ or contributors of content.
              </li>
              <br />
              <li>
                Please read these Terms of Service carefully before accessing or
                using our website. By accessing or using any part of the site,
                you agree to be bound by these Terms of Service. If you do not
                agree to all the terms and conditions of this agreement, then
                you may not access the website or use any services. If these
                Terms of Service are considered an offer, acceptance is
                expressly limited to these Terms of Service.
              </li>
              <br />
              <li>
                Any new features or tools which are added to the current store
                shall also be subject to the Terms of Service. You can review
                the most current version of the Terms of Service at any time on
                this page. We reserve the right to update, change or replace any
                part of these Terms of Service by posting updates and/or changes
                to our website. It is your responsibility to check this page
                periodically for changes. Your continued use of or access to the
                website following the posting of any changes constitutes
                acceptance of those changes.
              </li>
            </ul>
          </p>
          <br />
          <h4>9.2 Website terms</h4>
          <p>
            <ul>
              <li>
                By agreeing to these Terms of Service, you represent that you
                are at least the age of majority in your state or province of
                residence, or that you are the age of majority in your state or
                province of residence and you have given us your consent to
                allow any of your minor dependents to use this site.
              </li>
              <br />
              <li>
                You may not use our products for any illegal or unauthorized
                purpose nor may you, in the use of the Service, violate any laws
                in your jurisdiction (including but not limited to copyright
                laws). • You must not transmit any worms or viruses or any code
                of a destructive nature.
              </li>
              <br />
              <li>
                A breach or violation of any of the Terms will result in an
                immediate termination of your Services.
              </li>
            </ul>
          </p>
          <br />
          <h4>9.3 General Conditions</h4>
          <p>
            <ul>
              <li>
                We reserve the right to refuse service to anyone for any reason
                at any time.
              </li>
              <br />
              <li>
                You agree not to reproduce, duplicate, copy, sell, resell or
                exploit any portion of the Service, use of the Service, or
                access to the Service or any contact on the website through
                which the service is provided, without express written
                permission by us.
              </li>
              <br />
              <li>
                The headings used in this agreement are included for convenience
                only and will not limit or otherwise affect these Terms
              </li>
            </ul>
          </p>
          <br />
          <h4>9.4 Accuracy, completeness and timeliness of information</h4>
          <p>
            <ul>
              <li>
                We are not responsible if information made available on this
                site is not accurate, complete or current. The material on this
                site is provided for general information only and should not be
                relied upon or used as the sole basis for making decisions
                without consulting primary, more accurate, more complete or more
                timely sources of information. Any reliance on the material on
                this site is at your own risk.
              </li>
              <br />
              <li>
                This site may contain certain historical information. Historical
                information, necessarily, is not current and is provided for
                your reference only. We reserve the right to modify the contents
                of this site at any time, but we have no obligation to update
                any information on our site. You agree that it is your
                responsibility to monitor changes to our site.
              </li>
            </ul>
          </p>
          <br />
          <h4>9.5 Modifications to these services and prices</h4>
          <p>
            <ul>
              We reserve the right at any time to modify or discontinue the
              Service (or any part or content thereof) without notice at any
              time.
              <br />
              <li>
                We shall not be liable to you or to any third-party for any
                modification, price change, suspension or discontinuance of the
                Service.
              </li>
            </ul>
          </p>
          <br />
          <h4>9.6 Products and services</h4>
          <p>
            <ul>
              <li>
                We reserve the right, but are not obligated, to limit the sales
                of our products or Services to any person, geographic region or
                jurisdiction. We may exercise this right on a case-by-case
                basis. We reserve the right to limit the quantities of any
                products or services that we offer. All descriptions of products
                or product pricing are subject to change at anytime without
                notice, at the sole discretion of us. We reserve the right to
                discontinue any product at any time. Any offer for any product
                or service made on this site is void where prohibited.
              </li>
              <br />
              <li>
                We do not warrant that the quality of any products, services,
                information, or other material purchased or obtained by you will
                meet your expectations, or that any errors in the Service will
                be corrected.
              </li>
            </ul>
          </p>
          <h4>9.7 Optional Tools</h4>
          <p>
            <ul>
              <li>
                We may provide you with access to third-party tools over which
                we neither monitor nor have any control nor input.
              </li>
              <br />
              <li>
                You acknowledge and agree that we provide access to such tools
                ”as is” and “as available” without any warranties,
                representations or conditions of any kind and without any
                endorsement. We shall have no liability whatsoever arising from
                or relating to your use of optional third-party tools.
              </li>
              <br />
              <li>
                Any use by you of optional tools offered through the site is
                entirely at your own risk and discretion and you should ensure
                that you are familiar with and approve of the terms on which
                tools are provided by the relevant third-party provider(s).
              </li>
              <br />
              <li>
                We may also, in the future, offer new services and/or features
                through the website (including, the release of new tools and
                resources). Such new features and/or services shall also be
                subject to these Terms of Service.
              </li>
            </ul>
          </p>
          <br />
          <h4>9.8 Third Party Links</h4>
          <p>
            <ul>
              <li>
                Certain content, products and services available via our Service
                may include materials from third-parties.
              </li>
              <br />
              <li>
                Third-party links on this site may direct you to third-party
                websites that are not affiliated with us. We are not responsible
                for examining or evaluating the content or accuracy and we do
                not warrant and will not have any liability or responsibility
                for any third-party materials or websites, or for any other
                materials, products, or services of third-parties.
              </li>
              <br />
              <li>
                We are not liable for any harm or damages related to the
                purchase or use of goods, services, resources, content, or any
                other transactions made in connection with any third-party
                websites. Please review carefully the third-party's policies and
                practices and make sure you understand them before you engage in
                any transaction. Complaints, claims, concerns, or questions
                regarding third-party products should be directed to the
                third-party.
              </li>
            </ul>
          </p>
          <br />
          <h4>9.9 User comments, feedback and other submissions</h4>
          <p>
            If, at our request, you send certain specific submissions (for
            example contest entries) or without a request from us you send
            creative ideas, suggestions, proposals, plans, or other materials,
            whether online, by email, by postal mail, or otherwise
            (collectively, 'comments'), you agree that we may, at any time,
            without restriction, edit, copy, publish, distribute, translate and
            otherwise use in any medium any comments that you forward to us. We
            are and shall be under no obligation (1) to maintain any comments in
            confidence; (2) to pay compensation for any comments; or (3) to
            respond to any comments. • You agree that your comments will not
            violate any right of any third-party, including copyright,
            trademark, privacy, personality or other personal or proprietary
            right. You further agree that your comments will not contain
            libelous or otherwise unlawful, abusive or obscene material, or
            contain any computer virus or other malware that could in any way
            affect the operation of the Service or any related website. You may
            not use a false e mail address, pretend to be someone other than
            yourself, or otherwise mislead us or third-parties as to the origin
            of any comments. You are solely responsible for any comments you
            make and their accuracy. We take no responsibility and assume no
            liability for any comments posted by you or any third-party.
          </p>
          <br />
          <h4>9.10 Errors, inaccuracies and omissions</h4>
          <p>
            <ul>
              <li>
                Occasionally there may be information on our site or in the
                Service that contains typographical errors, inaccuracies or
                omissions that may relate to product descriptions, pricing,
                promotions, offers, product shipping charges, transit times and
                availability. We reserve the right to correct any errors,
                inaccuracies or omissions, and to change or update information
                or cancel orders if any information in the Service or on any
                related website is inaccurate at any time without prior notice
                (including after you have submitted your order).
              </li>
              <br />
              <li>
                We undertake no obligation to update, amend or clarify
                information in the Service or on any related website, including
                without limitation, pricing information, except as required by
                law. No specified update or refresh date applied in the Service
                or on any related website, should be taken to indicate that all
                information in the Service or on any related website has been
                modified or updated.
              </li>
            </ul>
          </p>
          <h4>9.11 Prohibited Uses</h4>
          <p>
            In addition to other prohibitions as set forth in the Terms of
            Service, you are prohibited from using the site or its content: (a)
            for any unlawful purpose; (b) to solicit others to perform or
            participate in any unlawful acts; (c) to violate any international,
            federal, provincial or state regulations, rules, laws, or local
            ordinances; (d) to infringe upon or violate our intellectual
            property rights or the intellectual property rights of others; (e)
            to harass, abuse, insult, harm, defame, slander, disparage,
            intimidate, or discriminate based on gender, sexual orientation,
            religion, ethnicity, race, age, national origin, or disability; (f)
            to submit false or misleading information; (g) to upload or transmit
            viruses or any other type of malicious code that will or may be used
            in any way that will affect the functionality or operation of the
            Service or of any related website, other websites, or the Internet;
            (h) to collect or track the personal information of others; (i) to
            spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any
            obscene or immoral purpose; or (k) to interfere with or circumvent
            the security features of the Service or any related website, other
            websites, or the Internet. We reserve the right to terminate your
            use of the Service or any related website for violating any of the
            prohibited uses.
          </p>
          <br />
          <h4>9.12 Disclaimer of warranties, limitation of liability</h4>
          <p>
            <ul>
              <li>
                We do not guarantee, represent or warrant that your use of our
                service will be uninterrupted, timely, secure or error-free. •
                We do not warrant that the results that may be obtained from the
                use of the service will be accurate or reliable.
              </li>
              <br />
              <li>
                You agree that from time to time we may remove the service for
                indefinite periods of time or cancel the service at any time,
                without notice to you. • You expressly agree that your use of,
                or inability to use, the service is at your sole risk. The
                service and all products and services delivered to you through
                the service are (except as expressly stated by us) provided 'as
                is' and 'as available' for your use, without any representation,
                warranties or conditions of any kind, either express or implied,
                including all implied warranties or conditions of
                merchantability, merchantable quality, fitness for a particular
                purpose, durability, title, and non-infringement.
              </li>
              <br />
              <li>
                In no case shall SmartSTEMs, our trustees, officers, employees,
                affiliates, agents, contractors, interns, suppliers, service
                providers or licensors be liable for any injury, loss, claim, or
                any direct, indirect, incidental, punitive, special, or
                consequential damages of any kind, including, without limitation
                lost profits, lost revenue, lost savings, loss of data,
                replacement costs, or any similar damages, whether based in
                contract, tort (including negligence), strict liability or
                otherwise, arising from your use of any of the service or any
                products procured using the service, or for any other claim
                related in any way to your use of the service or any product,
                including, but not limited to, any errors or omissions in any
                content, or any loss or damage of any kind incurred as a result
                of the use of the service or any content (or product) posted,
                transmitted, or otherwise made available via the service, even
                if advised of their possibility. Because some states or
                jurisdictions do not allow the exclusion or the limitation of
                liability for consequential or incidental damages, in such
                states or jurisdictions, our liability shall be limited to the
                maximum extent permitted by law.
              </li>
            </ul>
          </p>
          <br />
          <h4>9.13 Indemnification</h4>
          <p>
            <ul>
              <li>
                You agree to indemnify, defend and hold harmless SmartSTEMs and
                our parent, subsidiaries, affiliates, partners, officers,
                directors, agents, contractors, licensors, service providers,
                subcontractors, suppliers, interns and employees, harmless from
                any claim or demand, including reasonable attorneys’ fees, made
                by any third-party due to or arising out of your breach of these
                Terms of Service or the documents they incorporate by reference,
                or your violation of any law or the rights of a third-party.
              </li>
            </ul>
          </p>
          <br />
          <h4>9.14 Severability</h4>
          <p>
            <ul>
              <li>
                In the event that any provision of these Terms of Service is
                determined to be unlawful, void or unenforceable, such provision
                shall nonetheless be enforceable to the fullest extent permitted
                by applicable law, and the unenforceable portion shall be deemed
                to be severed from these Terms of Service, such determination
                shall not affect the validity and enforceability of any other
                remaining provisions.
              </li>
            </ul>
          </p>
          <br />
          <h4>9.15 Termination</h4>
          <p>
            <ul>
              <li>
                The obligations and liabilities of the parties incurred prior to
                the termination date shall survive the termination of this
                agreement for all purposes.
              </li>
              <br />
              <li>
                These Terms of Service are effective unless and until terminated
                by either you or us. You may terminate these Terms of Service at
                any time by notifying us that you no longer wish to use our
                Services, or when you cease using our site.
              </li>
              <br />
              <li>
                If in our sole judgment you fail, or we suspect that you have
                failed, to comply with any term or provision of these Terms of
                Service, we also may terminate this agreement at any time
                without notice and you will remain liable for all amounts due up
                to and including the date of termination; and/or accordingly may
                deny you access to our Services (or any part thereof).
              </li>
            </ul>
          </p>
          <br />
          <h4>9.16 Entire Agreement</h4>
          <p>
            <ul>
              <li>
                The failure of us to exercise or enforce any right or provision
                of these Terms of Service shall not constitute a waiver of such
                right or provision.
              </li>
              <br />
              <li>
                These Terms of Service and any policies or operating rules
                posted by us on this site or in respect to The Service
                constitutes the entire agreement and understanding between you
                and us and govern your use of the Service, superseding any prior
                or contemporaneous agreements, communications and proposals,
                whether oral or written, between you and us (including, but not
                limited to, any prior versions of the Terms of Service).
              </li>
              <br />
              <li>
                Any ambiguities in the interpretation of these Terms of Service
                shall not be construed against the drafting party.
              </li>
            </ul>
          </p>
          <br />
          <h4>9.17 Governing Law</h4>
          <p>
            <ul>
              <li>
                These Terms of Service and any separate agreements whereby we
                provide you Services shall be governed by and construed in
                accordance with the laws of the courts of Scotland
              </li>
            </ul>
          </p>
          <br />
          <h4>9.18 Changes to Terms of Service</h4>
          <p>
            <ul>
              <li>
                You can review the most current version of the Terms of Service
                at any time at this page.
              </li>
              <br />
              <li>
                We reserve the right, at our sole discretion, to update, change
                or replace any part of these Terms of Service by posting updates
                and changes to our website. It is your responsibility to check
                our website periodically for changes. Your continued use of or
                access to our website or the Service following the posting of
                any changes to these Terms of Service constitutes acceptance of
                those changes.
              </li>
            </ul>
          </p>
          <br />
          <h4>9.19 Contact Information</h4>
          <p>
            <ul>
              <li>
                Questions about the Terms of Service should be sent to us at
                hello@smartstems.org
              </li>
            </ul>
          </p>
          <br />
          <h4>10. Your rights</h4>
          <p>
            <br />
            <b>Access to your information –</b> You have the right to request a
            copy of the personal information about you that we hold.
            <br />
            <br />
            <b>Correcting your information –</b> We want to make sure that your
            personal information is accurate, complete and up to date and you
            may ask us to correct any personal information about you that you
            believe does not meet these standards.
            <br />
            <br />
            <b>Deletion of your information –</b> You have the right to ask us
            to delete personal information about you where:
            <ul>
              <br />
              <li>
                You consider that we no longer require the information for the
                purposes for which it was obtained
              </li>
              <br />
              <li>
                We are using that information with your consent and you have
                withdrawn your consent – see Withdrawing consent to using your
                information below
              </li>
              <br />
              <li>
                You have validly objected to our use of your personal
                information – see Objecting to how we may use your information
                below
              </li>
              <br />
              <li>
                Our use of your personal information is contrary to law or our
                other legal obligations.
              </li>
            </ul>
            <br />
            <br />
            Objecting to how we may use your information – You have the right at
            any time to require us to stop using your personal information for
            direct marketing purposes. In addition, where we use your personal
            information to perform tasks carried out in the public interest or
            pursuant to the legitimate interests of us or a third party then, if
            you ask us to, we will stop using that personal information unless
            there are overriding legitimate grounds to continue.
            <br />
            <br />
            <b>Restricting how we may use your information –</b> in some cases,
            you may ask us to restrict how we use your personal information.
            This right might apply, for example, where we are checking the
            accuracy of personal information about you that we hold or assessing
            the validity of any objection you have made to our use of your
            information. The right might also apply where this is no longer a
            basis for using your personal information, but you don't want us to
            delete the data. Where this right to validly exercised, we may only
            use the relevant personal information with your consent, for legal
            claims or where there are other public interest grounds to do so.
            <br />
            <br />
            <b>Portability –</b> if we process personal information that you
            provide to us on the basis of consent or because it is necessary for
            the performance of a contract to which you are party, and in either
            case that processing is carried out by automated means, then you
            have the right to have that personal information transmitted to you
            in a machine readable format. Where technically feasible, you also
            have the right to have that personal information transmitted
            directly to another controller.
            <br />
            <br />
            <b>Automated processing –</b> if we use your personal information on
            an automated basis to make decisions which significantly affect you,
            you have the right to ask that the decision be reviewed by an
            individual to whom you may make representations and contest the
            decision. This right only applies where we use your information with
            your consent or as part of a contractual relationship with you
            <br />
            <br />
            <b>Withdrawing consent using your information –</b> Where we use
            your personal information with your consent you may withdraw that
            consent at any time and we will stop using your personal information
            for the purpose(s) for which consent was given. Please contact us in
            any of the ways set out in the Contact information and further
            advice section if you wish to exercise any of these rights.
          </p>
          <br />
          <h4>11. Changes to our privacy statement</h4>
          <p>
            We keep this privacy statement under regular review and will place
            any updates on this website. Paper copies of the privacy statement
            may also be obtained by emailing hello@smartstems.org This privacy
            statement was last updated on 29/09/19.
          </p>
          <br />
          <h4>12. Contact information and further advice</h4>
          <p>For further details please contact hello@smartstems.org</p>
          <br />
          <h4>13. Complaints</h4>
          <p>
            We seek to resolve directly all complaints about how we handle
            personal information but you also have the right to lodge a
            complaint with the Information Commissioner’s Office: Online:
            https://ico.org.uk/global/contact-us/email/ By phone: 0303 123 1113
            By post: Information Commissioner's Office Wycliffe House Water Lane
            Wilmslow SK9 5AF
          </p>
        </div>
      </Modal>
    );
  }
}

export default TermsAndConditions;
