import React, {Component} from "../../../node_modules/react";
import {
  Button
} from "../../../node_modules/antd";
import CreateUserModal from "../../modals/CreateUserModal";
class UserTopButtonPanel extends Component {
  state = {
    size: "large",
    visible: false
  };
  componentDidMount() {
    this.setState({ edit: this.props.edit });
  }
  handleSizeChange = e => {
    this.setState({ size: e.target.value });
  };
  showModal = () => {
    this.setState({
      visible: true
    });
  };
  handleEdit  = () => {
    this.setState({
      edit: false
    });
  };
  handleCancel = e => {
    const { toggleEdit } = this.props;
    toggleEdit(false);
    this.setState({
      visible: false
    });
  };

  render() {
    const size = this.state.size;
    const { edit, record, getData } = this.props;
    return (
      <div className="gx-card">
        <Button
          type="primary"
          icon="plus"
          size={size}
          onClick={() => this.showModal()}
          style={{ marginLeft: 20, marginTop: 20 }}
        >
          Create New User
        </Button>
        <CreateUserModal
          visible={this.state.visible || edit}
          handleCancel={() => this.handleCancel()}
          edit={edit}
          record={record}
          getData={() => getData()}
          role={"superadmin"}
          userType="superadmin"
          adminUser
        />
      </div>
    );
  }
}


export default UserTopButtonPanel;
