import React from "react";
import { CLIENT_ID, REDIRECT_URI, TENANT_ID } from "../../constants/config";

export default ({ large }) => {
  const url = `https://login.microsoftonline.com/${TENANT_ID}/oauth2/v2.0/authorize?client_id=${CLIENT_ID}&response_type=code&redirect_uri=${REDIRECT_URI}&response_mode=query&scope=Calendars.ReadWrite%20User.Read&state=12345&prompt=consent`
  return (
    <><a href={url}>CONNECT MS ACCOUNT</a> {large ? 'to manage event bookings' : ''}</>
  )
};
//https://login.microsoftonline.com/7436384e-be4e-4217-9bd3-6fdbd4604000/oauth2/v2.0/authorize?client_id=799416c7-d3ef-493b-befa-416a6171e5f8&response_type=code&response_mode=query&scope=calendars.readwrite&state=12345

