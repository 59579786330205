import React, {Component} from "../../../node_modules/react";
import {Button} from "../../../node_modules/antd";
import CreateEventModal from '../../modals/CreateEventModal';
class TopButtonPanel extends Component {
  state = {
    size: "large",
    visible: false
  };
  componentDidMount() {
    this.setState({ edit: this.props.edit });
  }
  handleSizeChange = e => {
    this.setState({ size: e.target.value });
  };
  showModal = () => {
    this.setState({
      visible: true
    });
  };
  handleEdit  = () => {
    this.setState({
      edit: false
    });
  };
  handleCancel = e => {
    const { toggleEdit } = this.props;
    toggleEdit(false);
    this.setState({
      visible: false
    });
  };

  render() {
    const size = this.state.size;
    const { edit, record, getData } = this.props;
    return (
      <div className="gx-card">
        <Button
          type="primary"
          icon="plus"
          size={size}
          onClick={() => this.showModal()}
          style={{ marginLeft: 20, marginTop: 20 }}
        >
          Create New Event
        </Button>
        {
          (this.state.visible || edit) && (
            <CreateEventModal
              visible={this.state.visible || edit}
              handleCancel={() => this.handleCancel()}
              edit={edit}
              record={record}
              getData={getData}
            />
          )
        }
      </div>
    );
  }
}

export default TopButtonPanel;
