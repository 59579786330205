import React from "react";
import { Card, Divider, Table, Pagination, Input, Button, Icon } from "antd";
import { connect } from "react-redux";
import SchoolsPanel from "../../components/topButtonPanel/SchoolsPanel";
import axios from "../../util/Api";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import { withRouter } from "react-router";
import ReactDOM from "react-dom";
import Highlighter from "react-highlight-words";

const expandedRowRender = record => <p>{record.description}</p>;
const title = () => 'Here is title';
const showHeader = true;
const footer = () => 'Here is footer';
const scroll = {y: 240};
const pagination = {position: 'bottom'};

class Dynamic extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      bordered: true,
      loading: false,
      pagination,
      size: "default",
      expandedRowRender: false,
      title: undefined,
      showHeader,
      footer: false,
      rowSelection: false,
      scroll: undefined,
      eventData: [],
      userData: [],
      warning: false,
      edit: false,
      record: [],
      schools: [],
      total: 0,
      numberOfPages: 0,
      pageSize: 100,
      pageNumber: 0,
      searchText: "",
      searchedColumn: ""
    };
  }

  componentDidMount() {
    this.getData();
  }

  handleToggle = prop => {
    return enable => {
      this.setState({ [prop]: enable });
    };
  };

  handleSizeChange = e => {
    this.setState({ size: e.target.value });
  };

  handleExpandChange = enable => {
    this.setState({
      expandedRowRender: enable ? expandedRowRender : undefined
    });
  };

  handleTitleChange = enable => {
    this.setState({ title: enable ? title : undefined });
  };

  handleHeaderChange = enable => {
    this.setState({ showHeader: enable ? showHeader : false });
  };

  handleFooterChange = enable => {
    this.setState({ footer: enable ? footer : undefined });
  };

  handleRowSelectionChange = enable => {
    this.setState({ rowSelection: enable ? {} : undefined });
  };

  handleScollChange = enable => {
    this.setState({ scroll: enable ? scroll : undefined });
  };

  handlePaginationChange = e => {
    const { value } = e.target;
    this.setState({
      pagination: value === "none" ? false : { position: value }
    });
  };

  handleDelete = record => {
    this.setState({
      warning: true,
      EventId: record.id
    });
  };

  handleEdit = record => {
    this.setState({
      edit: true,
      record,
      EventId: record.id
    });
  };

  onCancelDelete = () => {
    this.setState({
      warning: false
    });
  };

  toggleEdit = edit => {
    this.setState({
      edit
    });
  };

  getData() {
    this.getSchools();
  }

  async getSchools(page, amount, searchText) {
    const { authUser } = this.props;
    if (!authUser) {
      return null;
    }
    console.log(searchText);
    const pageNumber = page - 1 || this.state.pageNumber;
    const configParams = page
      ? { page: pageNumber, amount, name: searchText }
      : { name: searchText };
    const config = {
      params: configParams
    };
    const res = await axios.get("school", config);
    const data = res.data;
    let { results, total } = data;
    const numberOfPages = total / amount;
    if (results) {
      results = results.filter(result => {
        return result.deletedAt === null && result.masterList === true;
      });
      this.setState({ schools: results, total, numberOfPages });
    }
  }

  renderTopPanel() {
    const { authUser } = this.props;
    const { edit, record } = this.state;
    if (authUser && authUser.role === "superadmin") {
      return (
        <SchoolsPanel
          edit={edit}
          record={record}
          toggleEdit={() => this.toggleEdit()}
          getData={() => this.getData()}
        />
      );
    }
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<Icon type="search" />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <Icon type="search" />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      )
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.getSchools(null, null, selectedKeys[0]);
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
    this.getSchools();
  };

  render() {
    const { schools } = this.state;
    const { authUser } = this.props;
    const schoolColumns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: 350,
        ...this.getColumnSearchProps("name")
      },
      {
        title: "Type",
        dataIndex: "schoolType",
        key: "schoolType",
        render: (text, record) => <span>{text}</span>
      },
      {
        title: "Location",
        dataIndex: "location",
        key: "location",
        render: (text, record) => <span>{text}</span>
      },
      {
        title: "Main Email",
        dataIndex: "keyContactEmail",
        key: "keyContactEmail",
        render: (text, record) => <span>{text}</span>
      },
      {
        title: "Main Tel.",
        dataIndex: "keyContactPhone",
        key: "keyContactPhone",
        render: (text, record) => <span>{text}</span>
      },
      {
        title: "Action",
        key: "action",
        width: 150,
        render: (text, record) => (
          <span>
            <span onClick={() => this.handleEdit(record)} className="gx-link">
              View info / Edit
            </span>
            <Divider type="vertical" />
          </span>
        )
      }
    ];

    if (!authUser) {
      return null;
    }

    return (
      <div>
        {this.renderTopPanel()}
        <Card>
          <Table
            className="gx-table-responsive"
            {...this.state}
            columns={schoolColumns}
            dataSource={schools}
            pagination={false}
          />
          <br />
          <Pagination
            onChange={(page, pageSize) => {
              this.getSchools(page, pageSize);
              ReactDOM.findDOMNode(this).scrollIntoView();
            }}
            total={this.state.total}
            pageSize={this.state.pageSize}
          />
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser };
};

export default withRouter(connect(mapStateToProps, null)(Dynamic));
