import React, { Component } from "react";
import {
  Form,
  Input,
  Select,
  Button,
  Modal,
  Checkbox,
} from "antd";
import axios from "util/Api";
import { API_URL } from "../constants/config";

const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
  labelCol: {
    xs: { span: 15 },
    sm: { span: 10 }
  },
  wrapperCol: {
    xs: { span: 15 },
    sm: { span: 15 },
    md: { span: 16 },
    lg: { span: 12 }
  }
};
class NewSchool extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      confirmLoading: false,
      schoolFieldsVisible: false,
      schoolData: [],
      SelectedSchoolId: null,
    };
  }
  componentDidMount() {
    this.getSchools();
  }

  createSchool = values => {
    const { EventId, handleCancel, getData, form } = this.props;
    const { SelectedSchoolId } = this.state;
    const {
      name,
      location,
      keyContactEmail,
      keyContactPhone,
      keyContactName,
      centreType,
      schoolType,
      additionalSupport,
      contactName1,
      contactEmail1,
      contactPhone1,
      contactName2,
      contactEmail2,
      contactPhone2,
      contactName3,
      contactEmail3,
      contactPhone3
    } = values;
    const data = {
      name,
      location,
      keyContactEmail,
      keyContactPhone,
      keyContactName,
      centreType,
      schoolType,
      additionalSupport,
      contactName1,
      contactEmail1,
      contactPhone1,
      contactName2,
      contactEmail2,
      contactPhone2,
      contactName3,
      contactEmail3,
      contactPhone3
    };
    return axios
      .post(`${API_URL}/school`, data)
      .then(res => {
        form.resetFields();
        handleCancel();
        getData();
      })
      .catch(function(error) {
        console.log("Error****:", error.message);
      });
  };

  editSchool = values => {
    const { handleCancel, record } = this.props;
    const {
      name,
      location,
      keyContactEmail,
      keyContactPhone,
      keyContactName,
      centreType,
      schoolType,
      additionalSupport,
      contactName1,
      contactEmail1,
      contactPhone1,
      contactName2,
      contactEmail2,
      contactPhone2,
      contactName3,
      contactEmail3,
      contactPhone3
    } = values;
    const data = {
      id: record.id,
      name,
      location,
      keyContactEmail,
      keyContactPhone,
      keyContactName,
      centreType,
      schoolType,
      additionalSupport,
      contactName1,
      contactEmail1,
      contactPhone1,
      contactName2,
      contactEmail2,
      contactPhone2,
      contactName3,
      contactEmail3,
      contactPhone3
    };
    return axios
      .put(`${API_URL}/school`, data)
      .then(({ res }) => {
        if (res) {
          this.props.form.resetFields();
          handleCancel();
        } else {
          this.props.form.resetFields();
          handleCancel();
        }
      })
      .catch(function(error) {
        console.log("Error****:", error.message);
      });
  };

  getSchools = () => {
    return axios
      .get("school")
      .then(schoolData => {
        this.setState({ schoolData: schoolData.data });
      })
      .catch(function(error) {
        console.log("Error****:", error.message);
      });
  };

  handleSubmit = e => {
    const { edit } = this.props;
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        edit ? this.editSchool(values) : this.createSchool(values);
      }
    });
  };

  renderTitle(title) {
    return (
      <div style={{ color: "#44A6DE", fontSize: 17, paddingLeft: 10 }}>
        {title}
      </div>
    );
  }

  onHandleCancel() {
    const { handleCancel, form } = this.props;
    form.resetFields();

    handleCancel();
  }

  render() {
    const { visible, form, edit, record } = this.props;
    const { getFieldDecorator } = form;
    const { confirmLoading } = this.state;
    return (
      <Modal
        maskClosable={false}
        okButtonProps={{
          form: "schoolForm",
          key: "submit",
          htmlType: "submit"
        }}
        title={edit ? `Edit School` : `Add School`}
        visible={visible}
        okText={`Save School`}
        confirmLoading={confirmLoading}
        onCancel={() => this.onHandleCancel()}
        width={900}
      >
        <Form form={form} onSubmit={this.handleSubmit} id="schoolForm">
          {this.renderTitle("School Details")}
          <br />
          <FormItem {...formItemLayout} label="School Name">
            {getFieldDecorator("name", {
              initialValue: edit ? record.name : "",
              rules: [{ required: true, message: "Please enter a school name" }]
            })(<Input placeholder="" />)}
          </FormItem>
          <FormItem {...formItemLayout} label="School Type">
            {getFieldDecorator("schoolType", {
              initialValue: edit ? record.schoolType : "",
              rules: [
                { required: true, message: "Please select a school type" }
              ]
            })(
              <Select>
                <Option value="Primary">Primary</Option>
                <Option value="Secondary">Secondary</Option>
                <Option value="Primary/Secondary">Primary & Secondary</Option>
                <Option value="Private">Private</Option>
                <Option value="Special">Special</Option>
              </Select>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="Center Type">
            {getFieldDecorator("centreType", {
              initialValue: edit ? record.centreType : "",
              rules: [
                { required: true, message: "Please select a center type" }
              ]
            })(
              <Select>
                <Option value="Local Authority">Local Authority</Option>
                <Option value="Independant">Independant</Option>
              </Select>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Location">
            {getFieldDecorator("location", {
              initialValue: edit ? record.location : "",
              rules: [
                { required: false, message: "Please enter a school name" }
              ]
            })(<Input placeholder="" />)}
          </FormItem>
          <br />
          {this.renderTitle("Main Contact Details")}
          <br />
          <FormItem {...formItemLayout} label="Main Contact Name">
            {getFieldDecorator("keyContactName", {
              initialValue: edit ? record.keyContactName : "",
              rules: [
                {
                  required: false,
                  message: "Please enter a schools target"
                }
              ]
            })(<Input placeholder="" id="keyContactName" />)}
          </FormItem>
          <FormItem {...formItemLayout} label="Main Contact Email">
            {getFieldDecorator("keyContactEmail", {
              initialValue: edit ? record.keyContactEmail : "",
              rules: [
                {
                  required: false,
                  message: "Please enter a schools target"
                }
              ]
            })(<Input placeholder="" id="keyContactEmail" />)}
          </FormItem>
          <FormItem {...formItemLayout} label="Main Contact Number">
            {getFieldDecorator("keyContactPhone", {
              initialValue: edit ? record.keyContactPhone : "",
              rules: [
                {
                  required: false,
                  message: "Please enter a schools target"
                }
              ]
            })(<Input placeholder="" id="keyContactPhone" />)}
          </FormItem>
          <br />
          {this.renderTitle("Contact 1")}
          <br />
          <FormItem {...formItemLayout} label="Contact Name 1">
            {getFieldDecorator("contactName1", {
              initialValue: edit ? record.contactName1 : "",
              rules: [
                {
                  required: false,
                  message: "Please enter a schools target"
                }
              ]
            })(<Input placeholder="" id="contactName1" />)}
          </FormItem>
          <FormItem {...formItemLayout} label="Contact Email 1">
            {getFieldDecorator("contactEmail1", {
              initialValue: edit ? record.contactEmail1 : "",
              rules: [
                {
                  required: false,
                  message: "Please enter a schools target"
                }
              ]
            })(<Input placeholder="" id="contactEmail1" />)}
          </FormItem>
          <FormItem {...formItemLayout} label="Contact Number 1">
            {getFieldDecorator("contactPhone1", {
              initialValue: edit ? record.contactPhone1 : "",
              rules: [
                {
                  required: false,
                  message: "Please enter a schools target"
                }
              ]
            })(<Input placeholder="" id="contactPhone1" />)}
          </FormItem>
          <br />
          {this.renderTitle("Contact 2")}
          <br />
          <FormItem {...formItemLayout} label="Contact Name 2">
            {getFieldDecorator("contactName2", {
              initialValue: edit ? record.contactName2 : "",
              rules: [
                {
                  required: false,
                  message: "Please enter a schools target"
                }
              ]
            })(<Input placeholder="" id="contactName2" />)}
          </FormItem>
          <FormItem {...formItemLayout} label="Contact Email 2">
            {getFieldDecorator("contactEmail2", {
              initialValue: edit ? record.contactEmail2 : "",
              rules: [
                {
                  required: false,
                  message: "Please enter a schools target"
                }
              ]
            })(<Input placeholder="" id="contactEmail2" />)}
          </FormItem>
          <FormItem {...formItemLayout} label="Contact Number 2">
            {getFieldDecorator("contactPhone2", {
              initialValue: edit ? record.contactPhone2 : "",
              rules: [
                {
                  required: false,
                  message: "Please enter a schools target"
                }
              ]
            })(<Input placeholder="" id="contactPhone2" />)}
          </FormItem>
          <br />
          {this.renderTitle("Contact 3")}
          <br />
          <FormItem {...formItemLayout} label="Contact Name 3">
            {getFieldDecorator("contactName3", {
              initialValue: edit ? record.contactName3 : "",
              rules: [
                {
                  required: false,
                  message: "Please enter a schools target"
                }
              ]
            })(<Input placeholder="" id="contactName3" />)}
          </FormItem>
          <FormItem {...formItemLayout} label="Contact Email 3">
            {getFieldDecorator("contactEmail3", {
              initialValue: edit ? record.contactEmail3 : "",
              rules: [
                {
                  required: false,
                  message: "Please enter a schools target"
                }
              ]
            })(<Input placeholder="" id="contactEmail3" />)}
          </FormItem>
          <FormItem {...formItemLayout} label="Contact Number 3">
            {getFieldDecorator("contactPhone3", {
              initialValue: edit ? record.contactPhone3 : "",
              rules: [
                {
                  required: false,
                  message: "Please enter a schools target"
                }
              ]
            })(<Input placeholder="" id="contactPhone3" />)}
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

const NewSchoolModal = Form.create()(NewSchool);
export default NewSchoolModal;
