import React from "react";
import {Button, Checkbox, Form, Input} from "antd";
import {connect} from "react-redux";
import { userPasswordChange } from "../appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";

const FormItem = Form.Item;

class ChangePassword extends React.Component {

  handleSubmit = (e) => {
    const { history } = this.props;
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
      this.props.history.push('/');
        this.props.userPasswordChange(values, history);
      }
    });
  };

  componentDidUpdate() {
    if (this.props.token !== null) {
      this.props.history.push('/');
    }
  }

  render() {
    const {getFieldDecorator} = this.props.form;

    let userColor = '';
    let welcomeHeader = '';
    const userType = this.props.match.params.userType;

    switch(userType) {
      case 'admin':
      userColor = '#3fc4f0';
      welcomeHeader = 'Admin';
      break;
      case 'fe':
      userColor = 'orange';
      welcomeHeader = 'Further/Higher Education';
      break;
      case 'workshop':
      userColor = '#2dcd5e';
      welcomeHeader = 'Workshop Host';
      break;
      case 'exhibitor':
      userColor = '#f64a94';
      welcomeHeader = 'Exhibitor';
      break;
      case 'speaker':
      userColor = '#fbed48';
      welcomeHeader = 'Speaker';
      break;
    }

    const bg = require('assets/images/main.jpg');

    return (
      <div
        className="gx-app-login-wrap"
        style={{
          backgroundImage: "url(" + bg + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover"
        }}
      >
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div
                className="gx-app-logo-content-bg"
                style={{ backgroundColor: userColor }}
              ></div>
              <div className="gx-app-logo-wid">
                <h1>{welcomeHeader} Change Temporary Password</h1>
                <p>
                  Welcome to SmartSTEMs {welcomeHeader} portal. Since it is the
                  first time logging in, please enter a new password.
                </p>
              </div>
              <div className="gx-app-logo">
                <img alt="example" src={require("assets/images/logo.png")} />
              </div>
            </div>
            <div className="gx-app-login-content">
              <Form
                onSubmit={this.handleSubmit}
                className="gx-signin-form gx-form-row0"
              >
                <FormItem name="password">
                  {getFieldDecorator("newPassword", {
                    rules: [
                      {
                        required: true,
                        message: "Please enter a new password"
                      },
                      {
                        min: 8,
                        message: "Password must be minimum 5 characters."
                      },
                      {
                        pattern: new RegExp("(?=.*[a-z])"),
                        message: "Include lowercase."
                      },
                      {
                        pattern: new RegExp("(?=.*[A-Z])"),
                        message: "Include uppercase."
                      },
                      {
                        pattern: new RegExp("(?=.*[0-9])"),
                        message: "Include digits."
                      },
                      {
                        pattern: new RegExp("(?=.*[-+_!@#$£%^&*.,?])"),
                        message: "Include symbols."
                      }
                    ]
                  })(<Input type="password" placeholder="New Password" />)}
                </FormItem>
                <FormItem dependencies={["password"]}>
                  {getFieldDecorator("confirmPassword", {
                    rules: [
                      {
                        required: true,
                        message: "Please confirm your password"
                      }
                    ]
                  })(<Input type="password" placeholder="Confirm Password" />)}
                </FormItem>
                <FormItem>
                  {getFieldDecorator("acceptTerms", {
                    rules: [
                      {
                        required: true,
                        message:
                          "Please accept terms and conditions to continue"
                      }
                    ]
                  })(
                    <Checkbox>
                      I agree to the <a href="">terms and conditions</a>
                    </Checkbox>
                  )}
                </FormItem>
                <FormItem>
                  <Button
                    type="primary"
                    style={{ float: "right" }}
                    className="gx-mb-0"
                    htmlType="submit"
                  >
                    <IntlMessages id="app.userAuth.signIn" />
                  </Button>
                </FormItem>
                <span className="gx-text-light gx-fs-sm">
                  You should have received login details from SmartSTEMs via
                  email
                </span>
              </Form>
            </div>
            <InfoView />
          </div>
        </div>
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(ChangePassword);

const mapStateToProps = ({auth}) => {
  const {token} = auth;
  return {token}
};

export default connect(mapStateToProps, {userPasswordChange})(WrappedNormalLoginForm);
